export const firebaseConfig = {
	apiKey: "AIzaSyDW4wAGwUmZb-thhnfzADgk50DSUJIoNUg",
	authDomain: "app-community-money-develop.firebaseapp.com",
	databaseURL: "https://app-community-money-develop.firebaseio.com",
	projectId: "app-community-money-develop",
	storageBucket: "app-community-money-develop.appspot.com",
	messagingSenderId: "373731866555",
	appId: "1:373731866555:web:632062d157e76b8fe2b69e",
	measurementId: "G-QXTWRF7QXV"
}
