import React from "react";
import {LocalizeContextProps, withLocalize} from "react-localize-redux";
import InputWithLabel from "./InputWithLabel";

export type FormBuilderField = {
	key: string
	type: string
	label: string
	conditionField: string
	initial: any
}

export interface FormBuilderProps {
	fields: FormBuilderField[]
	handleChange: (key: string, value: string | boolean | number) => void
	obj: any
}

export const buildInitialData = (fields: FormBuilderField[]): any => {
	const result = {createdAt: Date.now().toLocaleString()} as any
	fields.map((field, idx) => {
		if (field.type === 'array') {
			result[field.key] = field.initial ? field.initial : []
		} else if (field.type === 'number') {
			result[field.key] = field.initial ? field.initial : 0
		} else if (field.type === 'datetime-local') {
			result[field.key] = field.initial ? field.initial : '2020-01-01T00:00'
		} else if (field.type === 'date') {
			result[field.key] = field.initial ? field.initial : '2020-01-01'
		} else {
			result[field.key] = field.initial ? field.initial : ''
		}

		return result[field.key]
	})
	return result
}

class FormBuilder extends React.Component<FormBuilderProps & LocalizeContextProps, any> {
	render() {
		return (
			<form>
				{this.props.fields.map((field, idx) => {
					if (field.conditionField && !this.props.obj[field.conditionField]) {
						return null
					}

					if (field.type === 'array') {
						return null
					}

					if (field.type === 'switch') {
						return (
							<InputWithLabel
								onChange={(val) => this.props.handleChange(field.key, val)}
								placeholder=""
								value={this.props.obj[field.key]}
								title={field.label}
								inputType="radio"
							/>
						)
					}

					if (field.type === 'datetime-local' || field.type === 'date') {
						return <InputWithLabel
							onChange={(val) => this.props.handleChange(field.key, val)}
							placeholder=""
							value={this.props.obj[field.key]}
							title={field.label}
							inputType={field.type}
						/>
					}

					if (field.type === 'number') {
						return (
							<InputWithLabel
								onChange={(val) => this.props.handleChange(field.key, val)}
								placeholder=""
								value={this.props.obj[field.key]}
								title={field.label}
								inputType="number"
							/>
						)
					}

					if (field.type === 'multiline') {
						return (
							<InputWithLabel
								onChange={(val) => this.props.handleChange(field.key, val)}
								placeholder=""
								value={this.props.obj[field.key]}
								title={field.label}
								inputType="textarea"
							/>
						)
					}

					return (
						<InputWithLabel
							onChange={(val) => this.props.handleChange(field.key, val)}
							placeholder=""
							value={this.props.obj[field.key]}
							title={field.label}
							inputType="text"
						/>
					)
				})}
			</form>
		)
	}
}

export default withLocalize(FormBuilder)
