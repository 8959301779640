import React from "react";
import {FormGroup, Input, Label} from "reactstrap";
import {Translate} from "react-localize-redux";
import {InputType} from "reactstrap/es/Input";

interface InputWithLabelProps {
	inputType?: InputType
	placeholder: string
	rows?: number
	title: string
	value: any
	onChange: (value: any) => void
}

class InputWithLabel extends React.Component<InputWithLabelProps, any> {
	private static defaultProps = {
		inputType: "text",
		multiline: false,
		rows: 1,
	}

	render() {
		return (
			<FormGroup>
				<Label>
					<Translate id={this.props.title}/>
				</Label>

				<Input
					type={this.props.inputType}
					placeholder={this.props.placeholder}
					value={this.props.value}
					onChange={(event) => this.props.onChange(event.target.value)}
				/>
			</FormGroup>
		)
	}
}

export default InputWithLabel
