import React from "react";
import {Translate} from "react-localize-redux";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {getCategoryOrTagFor} from "@odania-it/odania-common/dist/Utils/Utils";
import {Group, TParams} from "../../Models";
import {Button, Card, CardBody, Col, Row} from "reactstrap";
import LocaleLink from "../Common/LocaleLink";

interface DisplayGroupGridItemsProps {
	groups: Group[]
}

class DisplayGroupGridItems extends React.Component<DisplayGroupGridItemsProps & RouteComponentProps<TParams>, any> {
	constructor(props: any) {
		super(props);

		this.goTo = this.goTo.bind(this)
	}

	private goTo(slug: string) {
		console.log('goTo', this.props)
		this.props.history.push(`/${this.props.match.params.locale}/protected/groups/${slug}`)
	}

	render(): JSX.Element {
		return (
			<Row>
				{this.props.groups.map((group, idx) => {
					return (
						<Col xs={12} sm={6} md={6} key={idx}>
							<Card
								raised
								background
								style={{backgroundImage: "url(" + group.image + ")"}}
							>
								<CardBody background>
									<h6 className="title">
										{getCategoryOrTagFor(group as any)}
									</h6>
									<LocaleLink to={`groups/${group.slug}`}>
										<h3 className="title">
											{group.title}
										</h3>
									</LocaleLink>
									<p className="description">
										{group.description}
									</p>

									<Button round onClick={() => this.goTo(group.slug)} color="warning">
										<i className="tim-icons icon-align-left-2"/> <Translate id="Show Group"/>
									</Button>
								</CardBody>
							</Card>
						</Col>
					)
				})}
			</Row>
		)
	}
}

export default withRouter(DisplayGroupGridItems)
