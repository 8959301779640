import React from "react";
import {Translate} from "react-localize-redux";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {Group, TParams} from "../../Models";
import {firestoreDb, queryForDocuments} from "../../Components/Firebase";
import {Button, Col, Container, Row} from "reactstrap";
import GroupSectionPills from "../../Components/Groups/GroupSectionPills";
import {BootstrapContextProps} from "../../Components/Bootstrap/BootstrapContext";
import {withBootstrap} from "../../Components/Bootstrap/withBootstrap";

interface GroupsPageState {
	firstElement: any;
	lastElement: null;
	groups: Group[];
	isLoading: boolean;
}

class GroupsPage extends React.Component<BootstrapContextProps & RouteComponentProps<TParams>, GroupsPageState> {
	state = {
		groups: [],
		isLoading: true,
		firstElement: null,
		lastElement: null,
	}

	constructor(props: any) {
		super(props);

		this.handleClick = this.handleClick.bind(this);
		this.loadNext = this.loadNext.bind(this);
		this.loadPrevious = this.loadPrevious.bind(this);
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		document.body.scrollTop = 0;

		this.loadData();
	}

	loadData(startAfter = null, endBefore = null) {
		const collectionPath = `groups`;

		let query = firestoreDb
			.collection(collectionPath)
			.where('visibility', '==', 'public')
			.orderBy('title', 'asc')
			.limit(20)

		if (startAfter !== null) {
			query = query.startAfter(startAfter)
		} else if (endBefore !== null) {
			query = query.endBefore(endBefore)
		}

		queryForDocuments(query, result => {
			this.setState({
				groups: result.documents as Group[],
				isLoading: false,
				firstElement: result.documents[0].createdAt,
				lastElement: result.documents[result.documents.length - 1].createdAt,
			})
		}, err => {
			console.error('ERROR', err)
		})
	}

	loadNext() {
		this.loadData(this.state.lastElement);
	}

	loadPrevious() {
		this.loadData(null, this.state.firstElement);
	}

	handleClick() {
		this.props.history.push(`/${this.props.locale}/protected/groups/new/edit`)
	}

	render(): JSX.Element {
		const ref = React.createRef<HTMLDivElement>();

		return (
			<div ref={ref}>
				<Container justify="center" style={{marginBottom: '20px'}}>
					<Row>
						<Col className="textCenter">
							<h1 className="title">
								<Translate id="Groups"/>
							</h1>
							<h4 className="subtitle">
								<Translate id="List of public groups"/>
							</h4>

							<Button onClick={this.handleClick}>
								<i className="tim-icons icon-globe-2"/> <Translate id="New group"/>
							</Button>
						</Col>
					</Row>
				</Container>

				<Row>
					<Col>
						<GroupSectionPills groups={this.state.groups}
															 loadNext={this.loadNext}
															 loadPrevious={this.loadPrevious}/>
					</Col>
				</Row>
			</div>
		)
	}
}

export default withRouter(withBootstrap(GroupsPage))
