import crypto from 'crypto';
import {firestoreDb} from "../Firebase";

export const insertBooking = (uid: string, accountSlug: string, booking: any) => {
	const hash = crypto.createHash('md5').update(JSON.stringify(booking)).digest('hex')
	const slug = `${booking.bookingDate}-${hash}`
	firestoreDb.collection(`users/${uid}/accounts/${accountSlug}/bookings`)
		.doc(slug)
		.set(booking)
		.then(result => {
			console.log('Insert Result', result)
		})
		.catch(err => {
			console.error('Error', err)
		})
}

export const convertDate = (date: string) => {
	const parts = date.split('.')
	return `${parts[2]}-${parts[1]}-${parts[0]}`
}
