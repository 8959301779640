import {Translate} from "react-localize-redux";
import React from "react";

const NotFound = () => (
	<header className="App-header">
		<h1>
			<Translate id="The page you requested could not be found"/>
		</h1>
	</header>
)

export default NotFound
