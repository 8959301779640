import React from "react";
import {Button, TextField} from "@material-ui/core";
import update from "immutability-helper";
import FormBuilder, {FormBuilderField} from "../Forms/FormBuilder";
import {calculateCostInformation} from "./RealEstateInformationCalculation";
import {Loan, RealEstate} from "../../Models";
import {LocalizeContextProps, Translate, withLocalize} from "react-localize-redux";
import {Col, Row} from "reactstrap";

const localeNumberFormat = new Intl.NumberFormat('de-DE', {maximumFractionDigits: 2, minimumFractionDigits: 2})
const initialLoanData = [
	{title: 'Loan', amount: 0, interestRate: 2, initialRepayment: 2, startDate: "2020-01-01", endDate: "2040-12-01"}
]

export const realEstateFinancingInformation = [
	{key: 'equity', type: 'number', label: 'Equity'},
	{key: 'loans', type: 'array', label: 'Loans', initial: initialLoanData},
] as FormBuilderField[]

interface RealEstateFinancingProps {
	handleChange: (key: string, value: string | boolean | number | Loan[]) => void
	realEstate: RealEstate
}

export const calculateLoanInfo = (loan: Loan) => {
	const interestPerMonth = (loan.interestRate / 100) * loan.amount / 12
	const paymentPerMonth = ((loan.interestRate + loan.initialRepayment) / 100) * loan.amount / 12
	const repaymentPerMonth = (loan.initialRepayment / 100) * loan.amount / 12
	return {
		interestPerMonth: interestPerMonth,
		paymentPerMonth: paymentPerMonth,
		repaymentPerMonth: repaymentPerMonth,
	}
}

export const calculateTotalLoanInformation = (realEstate: any) => {
	let totalAmount = 0
	let totalInterestPerMonth = 0
	let totalPaymentPerMonth = 0
	let totalRepaymentPerMonth = 0

	for (const loan of realEstate.loans) {
		const loanInfo = calculateLoanInfo(loan)
		totalAmount += loan.amount
		totalInterestPerMonth += loanInfo.interestPerMonth
		totalPaymentPerMonth += loanInfo.paymentPerMonth
		totalRepaymentPerMonth += loanInfo.repaymentPerMonth
	}

	return {
		totalAmount: totalAmount,
		totalInterestPerMonth: totalInterestPerMonth,
		totalPaymentPerMonth: totalPaymentPerMonth,
		totalRepaymentPerMonth: totalRepaymentPerMonth,
	}
}

class RealEstateFinancing extends React.Component<RealEstateFinancingProps & LocalizeContextProps, any> {
	constructor(props: any) {
		super(props);

		this.addLoan = this.addLoan.bind(this)
		this.handleLoanChange = this.handleLoanChange.bind(this)
		this.removeLoan = this.removeLoan.bind(this)
		this.setEquityToAdditionalCosts = this.setEquityToAdditionalCosts.bind(this)
	}

	private addLoan() {
		const newValue = update(this.props.realEstate.loans, {
			$push: [
				initialLoanData[0],
			],
		})

		this.props.handleChange('loans', newValue)
	}

	private handleLoanChange(idx: number, key: string, value: string | number) {
		const newValue = update(this.props.realEstate.loans, {
			[idx]: {
				[key]: {$set: value},
			}
		})

		this.props.handleChange('loans', newValue)
	}

	private removeLoan(idx: number) {
		const newValue = update(this.props.realEstate.loans, {
			$splice: [[idx, 1]],
		})

		this.props.handleChange('loans', newValue)
	}

	private setEquityToAdditionalCosts() {
		const info = calculateCostInformation(this.props.realEstate)
		this.props.handleChange('equity', info.additionalCosts)
	}

	render() {
		const {realEstate, translate} = this.props

		return (
			<>
				<Row>
					<Col>
						<h1>
							<Translate id="Financing"/>
						</h1>
						<FormBuilder
							fields={realEstateFinancingInformation}
							handleChange={this.props.handleChange}
							obj={this.props.realEstate}
						/>
					</Col>
				</Row>
				<Row>
					<Col style={{margin: '20px 0 20px 0'}}>
						<Button variant="outlined" onClick={this.setEquityToAdditionalCosts} color="secondary">
							<Translate id="Set equity to additional costs"/>
						</Button>

						<Button variant="outlined" onClick={this.addLoan} color="primary">
							<Translate id="Add Loan"/>
						</Button>
					</Col>
				</Row>
				{realEstate.loans.map((loan, idx) => {
					const loanInfo = calculateLoanInfo(loan)

					return (
						<Row key={idx}>
							<Col>
								<TextField label={translate('Title')}
													 value={loan.title}
													 onChange={(event) =>
														 this.handleLoanChange(idx, 'title', event.target.value)}
								/>
								<TextField label={translate('Amount')}
													 value={loan.amount}
													 type="number"
													 onChange={(event) =>
														 this.handleLoanChange(idx, 'amount', Number(event.target.value))}
								/>
								<TextField label={translate('Interest Rate')}
													 value={loan.interestRate}
													 type="number"
													 onChange={(event) =>
														 this.handleLoanChange(idx, 'interestRate', Number(event.target.value))}
								/>
								<TextField label={translate('Initial Repayment')}
													 value={loan.initialRepayment}
													 type="number"
													 onChange={(event) =>
														 this.handleLoanChange(idx, 'initialRepayment', Number(event.target.value))}
								/>
								<TextField label={translate('Start Date')}
													 value={loan.startDate}
													 type="date"
													 onChange={(event) =>
														 this.handleLoanChange(idx, 'startDate', event.target.value)}
								/>
								<TextField label={translate('End Date')}
													 value={loan.endDate}
													 type="date"
													 onChange={(event) =>
														 this.handleLoanChange(idx, 'endDate', event.target.value)}
								/>
								<Button onClick={() => this.removeLoan(idx)}>
									x
								</Button>
								<br/>
								<Translate id="Capital Service per Month"/>: {localeNumberFormat.format(loanInfo.paymentPerMonth)}€
								<br/>
							</Col>
						</Row>
					)
				})}
			</>
		)
	}
}

export default withLocalize(RealEstateFinancing)
