import React from "react";
import {FormGroup, Input, Label} from "reactstrap";
import {LocalizeContextProps, Translate, withLocalize} from "react-localize-redux";

interface SelectOption {
	id: string
	title: string
}

interface SelectInputWithLabelProps {
	title: string
	value: any
	options: SelectOption[]
	onChange: (value: any) => void
}

class SelectInputWithLabel extends React.Component<SelectInputWithLabelProps & LocalizeContextProps, any> {
	render() {
		return (
			<FormGroup>
				<Label>
					<Translate id={this.props.title}/>
				</Label>

				<Input
					type="select"
					value={this.props.value}
					onChange={(event) => this.props.onChange(event.target.value)}
				>
					{this.props.options.map((value, idx) => {
						return (
							<option value={value.id} key={idx}>
								{this.props.translate(value.title)}
							</option>
						)
					})}
				</Input>
			</FormGroup>
		)
	}
}

export default withLocalize(SelectInputWithLabel)
