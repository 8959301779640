import {calculateTotalLoanInformation} from "./RealEstateFinancing";

export const calculateCostInformation = (realEstate: any) => {
	const equity = Number(realEstate.equity)
	const purchasePrice = Number(realEstate.purchasePrice)
	const livingSpace = Number(realEstate.livingSpace)
	const costPerQm = livingSpace > 0 ? purchasePrice / livingSpace : 0
	const brokerFee = calculateWithPercent(purchasePrice, Number(realEstate.brokerFeePercent))
	const notaryFee = calculateWithPercent(purchasePrice, Number(realEstate.notaryFeePercent))
	const landRegisterFee = calculateWithPercent(purchasePrice, Number(realEstate.landRegisterFeePercent))
	const realEstateTransferTax = calculateWithPercent(purchasePrice, Number(realEstate.realEstateTransferTaxPercent))

	const otherCostsPercent = Number(realEstate.otherCosts) / (purchasePrice / 100)
	const additionalCostsPercent = Number(realEstate.brokerFeePercent) + Number(realEstate.notaryFeePercent) +
		Number(realEstate.landRegisterFeePercent) + Number(realEstate.realEstateTransferTaxPercent) + otherCostsPercent
	const additionalCosts = calculateWithPercent(purchasePrice, additionalCostsPercent)
	const totalCosts = purchasePrice + additionalCosts
	const totalCostsPercent = totalCosts / (purchasePrice / 100)

	// Recommended Maintenance Reserve
	const recommendedMaintenanceReserve = (
		(realEstate.shareOfBuildingInPurchasePricePercent / 100 * purchasePrice) * (1 / 80 * 1.5) - (12*realEstate.commonPropertyReserve)
	) / realEstate.livingSpace

	const coldRentPerMonth = Number(realEstate.coldRentParkingSpace) + Number(realEstate.coldRent) + Number(realEstate.coldRentOther)
	const coldRentPerYear = coldRentPerMonth * 12
	const ownMaintenanceReserve = livingSpace * Number(realEstate.ownMaintenanceReservePerSqm) / 12
	const warmRentPerMonth = coldRentPerMonth + Number(realEstate.houseAllowanceApportionable) + Number(realEstate.propertyTax)
	const imputedLossOfRent = Number(realEstate.imputedLossOfRentPercent) / 100 * warmRentPerMonth
	const totalNotApportionable = Number(realEstate.houseAllowanceNotApportionable) +
		ownMaintenanceReserve + imputedLossOfRent
	const netRentalYield = (coldRentPerYear - 12 * totalNotApportionable) / totalCosts
	const totalApportionable = Number(realEstate.houseAllowanceApportionable) + Number(realEstate.propertyTax)
	const totalManagementCosts = totalApportionable + totalNotApportionable
	const managementCostsWithoutReserves = totalApportionable + Number(realEstate.houseAllowanceNotApportionable) + Number(realEstate.propertyTax)

	// Calculate interest per month
	const loanInformation = calculateTotalLoanInformation(realEstate)
	const cashflowBeforeTax = warmRentPerMonth - totalManagementCosts -
		loanInformation.totalRepaymentPerMonth - loanInformation.totalInterestPerMonth
	const deprecationPrice = (purchasePrice + additionalCosts) * Number(realEstate.shareOfBuildingInPurchasePricePercent) / 100
	const deprecationPerYear = deprecationPrice * Number(realEstate.deprecationPercent) / 100
	const taxableCashflow = warmRentPerMonth - (deprecationPerYear / 12) - loanInformation.totalInterestPerMonth -
		managementCostsWithoutReserves
	const personalMarginalTaxRate = 42
	const taxes = taxableCashflow * personalMarginalTaxRate / 100
	const cashflowAfterTax = cashflowBeforeTax - taxes
	const performanceFirstYear = purchasePrice * Number(realEstate.increaseInValuePerYearPercent) / 100
	const wealthGrowth = 12 * (loanInformation.totalRepaymentPerMonth+cashflowAfterTax) + performanceFirstYear
	const returnToEquity = equity > 0 ? wealthGrowth / equity * 100 : -1

	return {
		additionalCosts: additionalCosts,
		additionalCostsPercent: additionalCostsPercent,
		brokerFee: brokerFee,
		cashflowAfterTax: cashflowAfterTax,
		cashflowBeforeTax: cashflowBeforeTax,
		coldRentPerMonth: coldRentPerMonth,
		coldRentPerYear: coldRentPerYear,
		deprecationPrice: deprecationPrice,
		deprecationPerYear: deprecationPerYear,
		imputedLossOfRent: imputedLossOfRent,
		landRegisterFee: landRegisterFee,
		loanInformation: loanInformation,
		notaryFee: notaryFee,
		realEstateTransferTax: realEstateTransferTax,
		otherCostsPercent: otherCostsPercent,
		costPerQm: costPerQm,
		managementCostsWithoutReserves: managementCostsWithoutReserves,
		recommendedMaintenanceReserve: recommendedMaintenanceReserve,
		netRentalYield: netRentalYield,
		ownMaintenanceReserve: ownMaintenanceReserve,
		personalMarginalTaxRate: personalMarginalTaxRate,
		returnToEquity: returnToEquity,
		taxableCashflow: taxableCashflow,
		taxes: taxes,
		totalApportionable: totalApportionable,
		totalCosts: totalCosts,
		totalCostsPercent: totalCostsPercent,
		totalManagementCosts: totalManagementCosts,
		totalNotApportionable: totalNotApportionable,
		warmRentPerMonth: warmRentPerMonth,
		wealthGrowth: wealthGrowth,
	}
}

const calculateWithPercent = (purchasePrice: number, percent: number): number => {
	if (!percent || percent === 0) {
		return 0
	}

	return purchasePrice / 100 * percent
}
