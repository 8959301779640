import React from "react";
import {FormGroup, Label} from "reactstrap";
import {Translate} from "react-localize-redux";
import TagsInput from "react-tagsinput";

interface TagInputWithLabelProps {
	title: string
	value: any
	onChange: (tags: any[], changed: any[], changedIndexes: number[]) => void
}

class TagInputWithLabel extends React.Component<TagInputWithLabelProps, any> {
	private static defaultProps = {
		inputType: "text",
		multiline: false,
		rows: 1,
	}

	render() {
		return (
			<FormGroup>
				<Label>
					<Translate id={this.props.title}/>
				</Label>

				<TagsInput
					value={this.props.value}
					onChange={this.props.onChange}
				/>
			</FormGroup>
		)
	}
}

export default TagInputWithLabel
