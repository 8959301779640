export const translations = {
	'Additional Costs': 'Additional Costs',
	'Amount': 'Amount',
	'Basic Finance Calculator': 'Basic Finance Calculator',
	'Basic Real Estate Calculator': 'Basic Real Estate Calculator',
	'Basic Information': 'Basic Information',
	'Calculators': 'Calculators',
	'Edit group': 'Edit group',
	'Expenditure': 'Expenditure',
	'Group': 'Group',
	'Groups': 'Groups',
	'Last entries': 'Last entries',
	'List of public groups': 'List of public groups',
	'Monthly surplus': 'Monthly surplus',
	'New group': 'New group',
	'New row': 'New row',
	'per month': 'per month',
	'Public Group': 'Public Group',
	'Private group': 'Private group',
	'Protected group': 'Protected group',
	'Revenue': 'Revenue',
	'Result': 'Result',
	'Show Group': 'Show Group',
	'Total Expenditure': 'Total Expenditure',
	'Total Revenue': 'Total Revenue',
	'Welcome': 'Welcome',
	'Your success journal': 'Your success journal',
	'Your wishes and targets': 'Your wishes and targets',
	"Accounts": "Accounts",
	"Balance": "Balance",
	"Click to upload": "Click to upload",
	"About us": "About us",
	"Add": "Add",
	"Add content part": "Add Content Part",
	"Add locale": "Add Locale",
	"Address": "Address",
	"Accept": "Accept",
	"Align": "Align",
	"Allow Robot": "Allow Robot",
	"Asset": "Asset",
	"Asset Library": "Asset Library",
	"Assets": "Assets",
	"Body": "Body",
	"Budget": "Budget",
	"Buy Date": "Buy Date",
	"Cancel": "Cancel",
	"Categories": "Categories",
	"Category": "Category",
	"Comment": "Comment",
	"Community Money": "Community Money",
	"Contact us": "Contact us",
	"Contact form": "Contact form",
	"Content Part Type": "Content Part Type",
	"Content Visibility": "Content Visibility",
	"Copyright": "Copyright",
	"Creator": "Creator",
	"Data Protection": "Data Protection",
	"Description": "Description",
	"Domain": "Domain",
	"Domains": "Domains",
	"Duration": "Duration",
	"Edit": "Edit",
	"Edit Asset": "Edit Asset",
	"Edit Page": "Edit Page",
	"Edit Post": "Edit Post",
	"Email": "Email",
	"Email address": "Email address",
	"Footer Links": "Footer Links",
	"German": "German",
	"Group Info": "Group Info",
	"Home Body": "Startpage Body",
	"Home Title": "Startpage Title",
	"Icon": "Icon",
	"Image": "Image",
	"Image Copyright": "Image Copyright",
	"Imprint": "Imprint",
	"Language": "Language",
	"Last": "Last",
	"Latests Posts": "Latests Posts",
	"Locale": "Locale",
	"Loading": "Loading...",
	"Logout": "Logout",
	"Member": "Member",
	"Members": "Members",
	"Messages": "Messages",
	"Next": "Next",
	"No": "No",
	"Pages": "Pages",
	"Phone": "Phone",
	"Please wait": "Please wait",
	"Post your comment": "Post your comment",
	"Posts": "Posts",
	"Previous": "Previous",
	"Preview": "Preview",
	"Profile": "Profile",
	"Project": "Project",
	"Read Article": "Read Article",
	"Read more": "Read More",
	"Real Estates": "Real Estates",
	"Save": "Save",
	"Select": "Select",
	"Send us a message": "Send us a message",
	"Settings": "Settings",
	"Show": "Show",
	"Show Posts on Home Page": "Show Posts on Home Page",
	"Sites": "Sites",
	"Slug": "Slug",
	"Subtitle": "Subtitle",
	"Success Journal": "Success Journal",
	"Submit": "Submit",
	"Tags": "Tags",
	"time-from": "From",
	"time-to": "To",
	"Timesheet": "Timesheet",
	"Title": "Title",
	"This page is under construction. Please come back later!": "This page is under construction. Please come back later!",
	"Toggle Asset Library": "Toggle Asset Library",
	"Visibility": "Visibility",
	"Welcome Admin": "Welcome Admin",
	"We use cookies to enhance your experience.": "We use cookies to enhance your experience.",
	"Wishes and goals": "Wishes and goals",
	"Yes": "Yes",
	"You can contact us if you have any questions or suggestions. All Feedback Welcome.": "You can contact us if you have any questions or suggestions. All Feedback Welcome.",
	"You can discover": "You can discover",
	"Your message": "Your message",
	"Your Name": "Your Name",
	"Welcome to Community Money": "Welcome to Community Money",
	"Yearly surplus": "Yearly surplus",
	"per year": "per year",
	"language-name-de": "Deutsch",
	"language-name-en": "English",
	"Broker Fee (Percent)": "Broker Fee (Percent)",
	"Capital Service per Month": "Capital Service per Month",
	"Cashflow after tax": "Cashflow after tax",
	"Cashflow before tax": "Cashflow before tax",
	"Cold rent (per Month)": "Cold rent (per Month)",
	"Cost per qm": "Cost per qm",
	"Deprecation (Percent)": "Deprecation (Percent)",
	"Equity": "Equity",
	"Factor": "Factor",
	"Financing": "Financing",
	"Gross rental yield": "Gross rental yield",
	"Imputed loss of rent": "Imputed loss of rent",
	"Imputed loss of rent (Percent)": "Imputed loss of rent (Percent)",
	"Increase in value per year (Percent)": "Increase in value per year (Percent)",
	"Increase in value per year": "Increase in value per year",
	"Initial Costs": "Initial Costs",
	"Interest Rate": "Interest Rate",
	"Investments": "Investments",
	"Land Register Fee": "Land Register Fee",
	"Land Register Fee (Percent)": "Land Register Fee (Percent)",
	"Living Space": "Living Space",
	"Management costs without Reserves": "Management costs without Reserves",
	"Net rental yield": "Net rental yield",
	"Notary Fee": "Notary Fee",
	"Notary Fee (Percent)": "Notary Fee (Percent)",
	"Other Costs": "Other Costs",
	"Overview": "Overview",
	"Own Maintenance Reserve": "Own Maintenance Reserve",
	"Own Maintenance Reserve per sqm": "Own Maintenance Reserve per sqm",
	"Owned": "Owned",
	"Parking Space": "Parking Space",
	"Performance": "Performance",
	"Personal Marginal Tax Rate": "Personal Marginal Tax Rate",
	"Purchased": "Purchased",
	"Purchase Date": "Purchase Date",
	"Purchase Price": "Purchase Price",
	"Recommended Maintenance Reserve": "Recommended Maintenance Reserve",
	"Real Estate": "Real Estate",
	"Real Estate Transfer Tax": "Real Estate Transfer Tax",
	"Real Estate Transfer Tax (Percent)": "Real Estate Transfer Tax (Percent)",
	"Return to equity": "Return to equity",
	"Rental Fee": "Rental Fee",
	"Taxes": "Taxes",
	"Taxable Cashflow": "Taxable Cashflow",
	"Total costs": "Total costs",
	"Total Apportionable": "Total Apportionable",
	"Total Loan Amount": "Total Loan Amount",
	"Total not Apportionable": "Total not Apportionable",
	"Total interest": "Total interest",
	"Total repayment": "Total repayment",
	"Total management costs": "Total management costs",
	"Warm rent (per Month)": "Warm rent (per Month)",
	"Management": "Management",
	'Loans': "Loans",
	'Reserves': "Reserves",
	"Set equity to additional costs": "Set equity to additional costs",
	"Add Loan": "Add Loan",
	"The page you requested could not be found": "The page you requested could not be found",
	"Location": "Location",
	"Name": "Name",
	"No entries": "No entries",
	"New": "New",
	"Finance Overview": "Finance Overview",
	"Monetary Value": "Monetary Value",
	"Precious Metals": "Precious Metals",
	"Corporate Investments": "Corporate Investments",
	"Miscellaneous": "Miscellaneous",
	"Percent": "Percent",
	"Total Assets": "Total Assets",
	"Sum": "Sum",
}
