/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import {NavLink, withRouter} from "react-router-dom";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// reactstrap components
import {Collapse, Nav} from "reactstrap";
import {RouteData} from "../../communityMoneyRoutes";
import {RouteComponentProps} from "react-router";
import {TParams} from "../../Models";
import {withBootstrap} from "../Bootstrap/withBootstrap";
import {BootstrapContextProps} from "../Bootstrap/BootstrapContext";

let ps = null as PerfectScrollbar | null;

interface BooleanDictionary {
	[key: string]: boolean
}

interface SidebarLogo {
	innerLink?: string
	outerLink?: string
	imgSrc: string
	text: string
}

interface SidebarProps {
	activeColor: "primary" | "blue" | "green" | "orange" | "red"
	closeSidebar: () => void
	logo: SidebarLogo
	routes: RouteData[]
}

interface SidebarState {
	[key: string]: boolean
}

class Sidebar extends React.Component<SidebarProps & BootstrapContextProps & RouteComponentProps<TParams>, SidebarState> {
	private sidebarRef = React.createRef<HTMLDivElement>()

	constructor(props: any) {
		super(props);
		this.state = this.getCollapseStates(props.routes);
	}

	// this creates the intial state of this component based on the collapse routes
	// that it gets through this.props.routes
	getCollapseStates = (routes: RouteData[]) => {
		let initialState = {};
		routes.map((prop, key) => {
			if (prop.collapse) {
				const views = prop.views as RouteData[]

				initialState = {
					[prop.state as string]: this.getCollapseInitialState(views),
					...this.getCollapseStates(views),
					...initialState
				};
			}
			return null;
		});
		return initialState;
	};
	// this verifies if any of the collapses should be default opened on a rerender of this component
	// for example, on the refresh of the page,
	// while on the src/views/forms/RegularForms.js - route /admin/regular-forms
	getCollapseInitialState(routes: RouteData[]) {
		for (let i = 0; i < routes.length; i++) {
			const views = (routes[i].views ? routes[i].views : []) as RouteData[]
			if (routes[i].collapse && this.getCollapseInitialState(views)) {
				return true;
			} else if (window.location.href.indexOf(routes[i].path) !== -1) {
				return true;
			}
		}
		return false;
	}

	// this function creates the links and collapses that appear in the sidebar (left menu)
	createLinks = (routes: RouteData[]) => {
		return routes.map((prop, key) => {
			if (prop.redirect) {
				return null;
			}

			if (prop.collapse) {
				let st = {} as BooleanDictionary;
				st[prop.state as string] = !this.state[prop.state as string];
				return (
					<li
						className={this.getCollapseInitialState(prop.views as RouteData[]) ? "active" : ""}
						key={key}
					>
						<a
							href="#"
							data-toggle="collapse"
							aria-expanded={this.state[prop.state as string]}
							onClick={e => {
								e.preventDefault();
								this.setState(st);
							}}
						>
							{prop.icon !== undefined ? (
								<>
									<i className={prop.icon}/>
									<p>
										{prop.name}
										<b className="caret"/>
									</p>
								</>
							) : (
								<>
                  <span className="sidebar-mini-icon">
                    {prop.mini}
                  </span>
									<span className="sidebar-normal">
                    {prop.name}
										<b className="caret"/>
                  </span>
								</>
							)}
						</a>
						<Collapse isOpen={this.state[prop.state as string]}>
							<ul className="nav">{this.createLinks(prop.views as RouteData[])}</ul>
						</Collapse>
					</li>
				);
			}

			return (
				<li className={this.activeRoute(`/${this.props.locale}${prop.layout}${prop.path}`)} key={key}>
					<NavLink
						to={`/${this.props.locale}${prop.layout}${prop.path}`}
						activeClassName=""
						onClick={this.props.closeSidebar}
					>
						{prop.icon !== undefined ? (
							<>
								<i className={prop.icon}/>
								<p>{prop.name}</p>
							</>
						) : (
							<>
                <span className="sidebar-mini-icon">
                  {prop.mini}
                </span>
								<span className="sidebar-normal">
                  {prop.name}
                </span>
							</>
						)}
					</NavLink>
				</li>
			);
		});
	};
	// verifies if routeName is the one active (in browser input)
	activeRoute = (routeName: string) => {
		return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
	};

	componentDidMount() {
		// if you are using a Windows Machine, the scrollbars will have a Mac look
		if (navigator.platform.indexOf("Win") > -1) {
			ps = new PerfectScrollbar(this.sidebarRef as any, {
				suppressScrollX: true,
				suppressScrollY: false
			});
		}
	}

	componentWillUnmount() {
		// we need to destroy the false scrollbar when we navigate
		// to a page that doesn't have this component rendered
		if (navigator.platform.indexOf("Win") > -1 && ps) {
			ps.destroy();
		}
	}

	render() {
		const {activeColor, logo} = this.props;
		let logoImg = null;
		let logoText = null;
		if (logo !== undefined) {
			if (logo.outerLink !== undefined) {
				logoImg = (
					<a
						href={logo.outerLink}
						className="simple-text logo-mini"
						target="_blank"
						onClick={this.props.closeSidebar}
					>
						<div className="logo-img">
							<img src={logo.imgSrc} alt="Community.Money"/>
						</div>
					</a>
				);
				logoText = (
					<a
						href={logo.outerLink}
						className="simple-text logo-normal"
						target="_blank"
						onClick={this.props.closeSidebar}
					>
						{logo.text}
					</a>
				);
			} else {
				logoImg = (
					<NavLink
						to={logo.innerLink  as string}
						className="simple-text logo-mini"
						onClick={this.props.closeSidebar}
					>
						<div className="logo-img">
							<img src={logo.imgSrc} alt="Community.Money"/>
						</div>
					</NavLink>
				);
				logoText = (
					<NavLink
						to={logo.innerLink  as string}
						className="simple-text logo-normal"
						onClick={this.props.closeSidebar}
					>
						{logo.text}
					</NavLink>
				);
			}
		}
		return (
			<div className="sidebar" data-color={activeColor}>
				<div className="sidebar-wrapper" ref={this.sidebarRef}>
					{logoImg !== null || logoText !== null ? (
						<div className="logo">
							{logoImg}
							{logoText}
						</div>
					) : null}
					<Nav>{this.createLinks(this.props.routes)}</Nav>
				</div>
			</div>
		);
	}
}

export default withBootstrap(withRouter(Sidebar));
