import React from 'react';
import './App.css';
import {LocalizeProvider} from "react-localize-redux";
import BootstrapProvider from "./Components/Bootstrap/BootstrapProvider";
import RouterApp from "./RouterApp";
import {createBrowserHistory} from "history";
import {Router} from 'react-router-dom';

const hist = createBrowserHistory({basename: '/'});

function App() {
  return (
    <div className="App">
			<LocalizeProvider>
				<Router history={hist}>
					<BootstrapProvider>
						<RouterApp/>
					</BootstrapProvider>
				</Router>
			</LocalizeProvider>
    </div>
  );
}

export default App;
