import React from "react";
import {Button, Col, Row} from "reactstrap";
import LoadingIndicator from "../../Components/Common/LoadingIndicator";
import {firestoreDb} from "../../Components/Firebase";
import {BootstrapContextProps} from "../../Components/Bootstrap/BootstrapContext";
import {withBootstrap} from "../../Components/Bootstrap/withBootstrap";
import firebase from "firebase/app";
import {Translate} from "react-localize-redux";
import {RouteComponentProps} from "react-router";
import {TParams} from "../../Models";
import FormBuilder, {FormBuilderField} from "../../Components/Forms/FormBuilder";
import {CSVReader} from "react-papaparse";
import update from "immutability-helper";
import {convertDate, insertBooking} from "../../Components/Accounts/AccountUtils";

const accountInformation = [
	{key: 'title', type: 'text', label: 'Name'},
	{key: 'iban', type: 'text', label: 'IBAN / Account Number'},
	{key: 'bic', type: 'text', label: 'BIC / Bank Code'},
	{key: 'bankName', type: 'text', label: 'Bank Name'},
	{key: 'balance', type: 'number', label: 'Balance'},
	{key: 'description', type: 'multiline', label: 'Description'},
] as FormBuilderField[]

interface ShowAccountPageState {
	account: firebase.firestore.DocumentSnapshot
	loading: boolean
}

class ShowAccountPage extends React.Component<BootstrapContextProps & RouteComponentProps<TParams>, ShowAccountPageState> {
	private failedLines = 0
	private parsedLines = 0

	state = {
		account: {} as firebase.firestore.DocumentSnapshot,
		loading: true,
	}

	constructor(props: any) {
		super(props);

		this.handleChange = this.handleChange.bind(this)
		this.handleOnError = this.handleOnError.bind(this)
		this.saveAccount = this.saveAccount.bind(this)
	}

	componentDidMount() {
		this.loadData()
	}

	private loadData() {
		firestoreDb
			.collection(`users/${this.props.user?.uid}/accounts`)
			.doc(this.props.match.params.slug)
			.get()
			.then((snapshot) => {
				this.setState({account: snapshot, loading: false})
			})
	}

	private handleChange(key: string, value: string | boolean | number): void {
		console.log('handleChange', key, value)
		const newData = update(this.state.account, {
			[key]: {$set: value},
		})
		this.setState({account: newData})
	}

	private handleOnError(err: any, file: any, inputElem: any, reason: any) {
		console.log(err, reason)
	}

	private saveAccount() {
		const snapshot = this.state.account
		const account = snapshot.exists ? snapshot.data() : {}
		const slug = this.props.match.params.slug
		const uid = this.props.user?.uid

		if (account === undefined) {
			return
		}

		if (slug === 'new') {
			account.slug = `${uid}-${Date.now()}`
			account.uid = account.uid ? account.uid : uid
		}

		console.log('Saving Account', account)
		firestoreDb.collection(`users/${uid}/accounts`)
			.doc(account.slug)
			.set(account)
			.then(result => {
				console.log('saveAccount', result)

				if (slug !== account.slug) {
					this.props.history.push(`/protected/accounts/${account.slug}`)
				}

			})
			.catch(err => {
				console.error('saveAccount error', err)
			})
	}

	private handleOnDrop(data: any) {
		for (const row of data) {
			const booking = row.data
			if (booking.Verwendungszweck) {
				console.log('account.Konto', booking.Konto, booking)
				insertBooking(this.props.user?.uid as string, this.props.match.params.slug, {
					amount: Number(booking.Betrag.replace(',', '.')),
					bookingDate: convertDate(booking.Datum),
					valuation: convertDate(booking.Wertstellung),
					intendedUseForPayment: booking.Verwendungszweck,
					currency: booking['Währung'],
					category: booking.Kategorie,
				})

				this.parsedLines++
			} else {
				console.log('Kein Konto', booking)
				this.failedLines++
			}
		}
	}

	render() {
		if (this.state.loading) {
			return <LoadingIndicator/>
		}

		return (
			<>
				<Row>
					<Col>
						<h1>
							<Translate id="Account"/>
						</h1>

						<FormBuilder fields={accountInformation} handleChange={this.handleChange} obj={this.state.account}/>

						<Button onClick={this.saveAccount} variant="outlined" color="primary">
							<Translate id="Save"/>
						</Button>
					</Col>
				</Row>
				<Row>
					<Col>
						<CSVReader
							onDrop={this.handleOnDrop}
							onError={this.handleOnError}
							addRemoveButton={true}
							config={{header: true}}
						>
						<span>
							<Translate id="Click to upload"/>
						</span>
						</CSVReader>

						<br/>
						Parsed Lines: {this.parsedLines}
						<br/>
						Failed Lines: {this.failedLines}
					</Col>
				</Row>
			</>
		)
	}
}

export default withBootstrap(ShowAccountPage)
