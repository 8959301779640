import React from "react";
import {Redirect} from "react-router-dom";
import {BootstrapContextProps} from "../Bootstrap/BootstrapContext";
import {withBootstrap} from "../Bootstrap/withBootstrap";
import NotFound from "../../Pages/Errors/NotFound";

class RedirectToLocale extends React.Component<BootstrapContextProps, any> {
	render(): JSX.Element {
		const {languages} = this.props

		if (!languages || languages.length === 0) {
			return <NotFound/>
		}

		const language = window.navigator.language
		const selectedLanguage = languages.includes(language) ? language : languages[0]
		console.log('Redirecting', languages, language, selectedLanguage)
		return <Redirect to={'/' + selectedLanguage}/>
	}
}

export default withBootstrap(RedirectToLocale)
