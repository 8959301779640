import React from "react";
import {LocalizeContextProps, Translate, withLocalize} from "react-localize-redux";
import {BootstrapContextProps} from "../../Components/Bootstrap/BootstrapContext";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {Group, TParams} from "../../Models";
import {UserInfo, Visibility} from "@odania-it/odania-common/dist/CommonModels";
import {slugify} from "@odania-it/odania-common/dist/Utils/Utils";
import {firestoreDb, retrieveDocumentFromFirestore} from "../../Components/Firebase";
import TemplateHelper from "@odania-it/odania-common/dist/Components/Common/TemplateHelper";
import TagsInput from "react-tagsinput";
import {Button, Card, Col, Container, Input, Row} from "reactstrap";
import {withBootstrap} from "../../Components/Bootstrap/withBootstrap";
import InputWithLabel from "../../Components/Forms/InputWithLabel";
import TagInputWithLabel from "../../Components/Forms/TagInputWithLabel";
import SelectInputWithLabel from "../../Components/Forms/SelectInputWithLabel";

interface GroupsPageState {
	documentFound: boolean
	group: Group
	isLoading: boolean
	isNew: boolean
}

class EditGroupPage extends React.Component<BootstrapContextProps & LocalizeContextProps & RouteComponentProps<TParams>, GroupsPageState> {
	state = {
		documentFound: false,
		group: {
			locale: this.props.match.params.locale,
			tags: [] as string[],
			title: '',
			visibility: Visibility.PUBLIC,
			subtitle: '',
			description: '',
			users: [
				{
					uid: this.props.user?.uid,
					name: this.props.user?.displayName,
					image: this.props.user?.photoURL,
				}
			] as UserInfo[],
			user_count: 1,
		} as Group,
		isLoading: true,
		isNew: false,
	}

	constructor(props: BootstrapContextProps & LocalizeContextProps & RouteComponentProps<TParams>) {
		super(props);

		this.saveGroup = this.saveGroup.bind(this)
	}

	componentDidMount(): void {
		window.scrollTo(0, 0);
		document.body.scrollTop = 0;

		this.loadData()
	}

	loadData(): void {
		const docId = this.props.match.params.slug
		if (docId === 'new') {
			console.debug('New document. Not loading previous data')
			this.setState({documentFound: true, isLoading: false, isNew: true})
			return
		}

		const locale = this.props.match.params.locale;
		if (!locale) {
			console.warn('No locale');
			return;
		}

		const collectionPath = `contents/${locale}/groups`;
		retrieveDocumentFromFirestore(collectionPath, docId, doc => {
			if (!doc.exists) {
				console.error(`Document does not exist! ${collectionPath}/${docId}`);
				this.setState({documentFound: false, isLoading: false})
				return;
			}

			this.setState({
				documentFound: true,
				group: doc.data() as Group,
				isLoading: false,
			})
		}, err => {
			console.error('ERROR', err)
			this.setState({documentFound: false, isLoading: false})
		})
	}

	saveGroup(): void {
		const group = this.state.group

		if (!group.slug) {
			group.slug = slugify(group.title)
			group.owner = {
				uid: this.props.user?.uid as string,
				name: this.props.user?.displayName as string,
				image: this.props.user?.photoURL as string,
			}
			group.users = [
				{
					uid: this.props.user?.uid as string,
					name: this.props.user?.displayName as string,
					image: this.props.user?.photoURL as string,
				},
			]
		}

		if (!group.slug) {
			console.error('No slug!', group.slug)
			return
		}

		firestoreDb.collection('groups')
			.doc(group.slug)
			.set(group)
			.then(result => {
				console.log('Result', result)

				if (this.state.isNew) {
					this.props.history.push(`/${this.props.match.params.locale}/groups/${group.slug}/edit`)
				}
			})
			.catch(err => {
				console.error(err)
			})
	}

	validate(): void {

	}

	render(): JSX.Element {
		const {translate} = this.props
		const {group} = this.state

		console.log(this.state)

		return (
			<div>
				<div className="container">
					<Container justify="center">
						<Row md={8} className="text-center">
							<Col>
								<h1 className="title">
									{!this.state.isNew && <Translate id="Edit group"/>}
									{this.state.isNew && <Translate id="New group"/>}
								</h1>
							</Col>
						</Row>
					</Container>
				</div>
				<form>
					<Container justify="center">
						<Row>
							<Col>
								<Card>
									<h2>
										<Translate id="Group Info"/>
									</h2>

									<InputWithLabel
										title="Title"
										value={group.title}
										placeholder="ETF Investment Gruppe"
										onChange={(value) => TemplateHelper.updateNestedSelectField(this, 'group', 'title', value)}
									/>
									<br/>
									<InputWithLabel
										placeholder={translate('Gruppe rund um ETF Investments') as string}
										title="Subtitle"
										value={group.subtitle}
										onChange={(value) => TemplateHelper.updateNestedSelectField(this, 'group', 'subtitle', value)}
									/>
								</Card>
								<Card>
									<InputWithLabel
										placeholder=""
										title="Description"
										value={group.description}
										inputType="textarea"
										rows={5}
										onChange={(value) => TemplateHelper.updateNestedSelectField(this, 'group', 'description', value)}
									/>
								</Card>
								<Card>
									<TagInputWithLabel
										title="Tags"
										value={group.tags}
										onChange={tags => TemplateHelper.updateNestedSelectField(this, 'group', 'tags', tags)}
									/>

									<SelectInputWithLabel
										onChange={(value) => TemplateHelper.updateNestedSelectField(this, 'group', 'locale', value) }
										title="Language"
										value={group.locale}
										options={this.props.languages.map((language, idx) => {
											return {id: language, title: language}
										})}
									/>

									<SelectInputWithLabel
										onChange={(value) => TemplateHelper.updateNestedSelectField(this, 'group', 'visibility', value) }
										title="Visibility"
										value={group.visibility}
										options={[
											{id: Visibility.PUBLIC, title: 'Public Group'},
											{id: Visibility.PROTECTED, title: 'Protected Group'},
											{id: Visibility.PRIVATE, title: 'Private Group'},
										]}
									/>
								</Card>
								<Card>
									<Button color="primary" variant="contained" onClick={this.saveGroup}>
										<i className="tim-icons icon-send"/> <Translate id="Save"/>
									</Button>
								</Card>
							</Col>
						</Row>
					</Container>
				</form>
			</div>
		)
	}
}

export default withBootstrap(withLocalize(withRouter(EditGroupPage)))
