export const translations = {
	'Additional Costs': 'Nebenkosten',
	'Amount': 'Summe',
	'Basic Finance Calculator': 'Basis Finanz Rechner',
	'Basic Real Estate Calculator': 'Basis Immobilien Rechner',
	'Basic Information': 'Basis Informationen',
	'Calculators': 'Rechner',
	'Edit group': 'Gruppe bearbeiten',
	'Expenditure': 'Ausgaben',
	'Group': 'Gruppe',
	'Groups': 'Gruppen',
	'Last entries': 'Letzte Einträge',
	'List of public groups': 'Liste der öffentlichen Gruppen',
	'Monthly surplus': 'Monatlicher Überschuss',
	'New group': 'Neue Gruppe',
	'New row': 'Neue Zeile',
	'per month': 'pro Monat',
	'Public Group': 'Öffentliche Gruppe',
	'Private group': 'Private Gruppe',
	'Protected group': 'Gesicherte Gruppe',
	'Revenue': 'Einnahmen',
	'Result': 'Ergebnis',
	'Show Group': 'Gruppe anzeigen',
	'Total Expenditure': 'Gesamtausgaben',
	'Total Revenue': 'Gesamteinnahmen',
	'Welcome': 'Willkommen',
	'Your success journal': 'Dein Erfolgsjournal',
	'Your wishes and targets': 'Deine Ziele und Wünsche',
	"Accounts": "Konten",
	"Balance": "Kontostand",
	"Click to upload": "Click to upload",
	"About us": "Über uns",
	"Add": "Hinzufügen",
	"Add content part": "ContentPart hinzufügen",
	"Add locale": "Sprache hinzufügen",
	"Address": "Adresse",
	"Accept": "Akzeptieren",
	"Align": "Align",
	"Allow Robot": "Robot erlauben",
	"Asset": "Asset",
	"Asset Library": "Asset Bibliothek",
	"Assets": "Assets",
	"Body": "Text",
	"Budget": "Haushaltsplan",
	"Buy Date": "Kaufdatum",
	"Cancel": "Abbrechen",
	"Categories": "Kategorien",
	"Category": "Kategorie",
	"Comment": "Kommentar",
	"Community Money": "Community Money",
	"Contact us": "Kontakt",
	"Contact form": "Kontakformular",
	"Content Part Type": "ContentPart Typ",
	"Content Visibility": "Sichtbarkeit",
	"Copyright": "Copyright",
	"Creator": "Erstellt von",
	"Data Protection": "Datenschutz",
	"Description": "Beschreibung",
	"Domain": "Domain",
	"Domains": "Domains",
	"Duration": "Duration",
	"Edit": "Bearbeiten",
	"Edit Asset": "Asset bearbeiten",
	"Edit Page": "Seite bearbeiten",
	"Edit Post": "Beitrag bearbeiten",
	"Email": "Email",
	"Email address": "Email Adresse",
	"Footer Links": "Footer Links",
	"German": "Deutsch",
	"Group Info": "Gruppen Info",
	"Home Body": "Startseiten Text",
	"Home Title": "Startseiten Titel",
	"Icon": "Icon",
	"Image": "Bild",
	"Image Copyright": "Bild Copyright",
	"Imprint": "Impressum",
	"Language": "Sprache",
	"Last": "Vorige",
	"Latests Posts": "Neueste Beiträge",
	"Locale": "Sprache",
	"Loading": "Loading...",
	"Logout": "Abmelden",
	"Member": "Mitglieder",
	"Members": "Mitglieder",
	"Messages": "Nachrichten",
	"Next": "Nächste",
	"No": "Nein",
	"Pages": "Seiten",
	"Phone": "Telefon",
	"Please wait": "Bitte warten",
	"Post your comment": "Schreibe einen Kommentar",
	"Posts": "Beiträge",
	"Previous": "Vorige",
	"Preview": "Vorschau",
	"Profile": "Profil",
	"Project": "Project",
	"Real Estates": "Immobilien",
	"Read Article": "Artikel lesen",
	"Read more": "Weiterlesen",
	"Save": "Speichern",
	"Select": "Auswählen",
	"Send us a message": "Sende uns eine Nachricht",
	"Settings": "Einstellungen",
	"Show": "Zeigen",
	"Show Posts on Home Page": "Zeige Beiträge auf der Startseite",
	"Sites": "Seiten",
	"Slug": "Slug",
	"Subtitle": "Subtitle",
	"Success Journal": "Erfolgsjournal",
	"Submit": "Senden",
	"Tags": "Tags",
	"time-from": "Von",
	"time-to": "Bis",
	"Timesheet": "Timesheet",
	"Title": "Titel",
	"This page is under construction. Please come back later!": "Diese Seite befindet sich noch in der Entwicklung. Bitte komme später wieder!",
	"Toggle Asset Library": "Toggle Asset Library",
	"Visibility": "Sichtbarkeit",
	"Welcome Admin": "Willkommen Admin",
	"We use cookies to enhance your experience.": "Wir benutzen Cookies um deinen Besuch optimal zu gestalten.",
	"Wishes and goals": "Wünsche und Ziele",
	"Yes": "Ja",
	"You can contact us if you have any questions or suggestions. All Feedback Welcome.": "Du kannst uns gerne kontaktieren. Fragen, Vorschläge und Feedback ist Willkommen.",
	"You can discover": "Schaue alternativ hier",
	"Your message": "Deine Nachricht",
	"Your Name": "Dein Name",
	"Welcome to Community Money": "Willkommen bei Community Money",
	"Yearly surplus": "Jährlicher Überschuss",
	"per year": "pro Jahr",
	"language-name-de": "Deutsch",
	"language-name-en": "English",
	"Broker Fee (Percent)": "Maklerkosten",
	"Capital Service per Month": "Kapitaldienst pro Monat",
	"Cashflow after tax": "Cashflow nach Steuern",
	"Cashflow before tax": "Cashflow vor Steuern",
	"Cold rent (per Month)": "Kaltmiete (pro Monat)",
	"Cost per qm": "Kosten pro qm",
	"Deprecation (Percent)": "Abchreibung (Prozent)",
	"Equity": "Eigenkapital",
	"Factor": "Faktor",
	"Financing": "Finanzierung",
	"Gross rental yield": "Bruttomietrendite",
	"Imputed loss of rent": "Kalkulatorischer Mietausfall",
	"Imputed loss of rent (Percent)": "Kalkulatorischer Mietausfall (Prozent)",
	"Increase in value per year (Percent)": "Vermögenszuwachs pro Jahr (Prozent)",
	"Increase in value per year": "Vermögenszuwach im Jahr",
	"Initial Costs": "Anfängliche Kosten",
	"Interest Rate": "Zinssatz",
	"Investments": "Investments",
	"Land Register Fee": "Grundbuchgebühr",
	"Land Register Fee (Percent)": "Grundbuchgebühr (Prozent)",
	"Living Space": "Wohnfläche",
	"Management costs without Reserves": "Betriebskosten ohne Rücklagen",
	"Net rental yield": "Netto Mietrendite",
	"Notary Fee": "Notarkosten",
	"Notary Fee (Percent)": "Notarkosten (Prozent)",
	"Other Costs": "Andere Kosten",
	"Overview": "Übersicht",
	"Own Maintenance Reserve": "Eigene Instandhaltungsrücklage",
	"Own Maintenance Reserve per sqm": "Eigene Instandhaltungsrücklage pro qm",
	"Owned": "Gekauft",
	"Parking Space": "Parkplätze",
	"Personal Marginal Tax Rate": "Persönlicher Grenzsteuersatz",
	"Performance": "Wertentwicklung",
	"Purchased": "Gekauft",
	"Purchase Date": "Kaufdatum",
	"Purchase Price": "Kaufpreis",
	"Recommended Maintenance Reserve": "Empfohlene Instandhaltungsrücklage",
	"Real Estate": "Immobilie",
	"Real Estate Transfer Tax": "Grunderwerbssteuer",
	"Real Estate Transfer Tax (Percent)": "Grunderwerbssteuer (Prozent)",
	"Return to equity": "Eigenkapitalrendite",
	"Rental Fee": "Miete",
	"Taxes": "Steuern",
	"Taxable Cashflow": "Taxable Cashflow",
	"Total costs": "Gesamtkosten",
	"Total Apportionable": "Gesamte umlagefähige Kosten",
	"Total not Apportionable": "Gesamte nicht umlagefähige Kosten",
	"Total interest": "Gesamte Zinsen",
	"Total repayment": "Gesamte Tilgung",
	"Total Loan Amount": "Gesamte Darlehenssumme",
	"Total management costs": "Gesamte Betriebskosten",
	"Warm rent (per Month)": "Warmmiete (pro Monat)",
	"Management": "Management",
	'Loans': "Kredite",
	'Reserves': "Rücklagen",
	"Set equity to additional costs": "Eigenkapital auf zusätzliche Kosten setzen",
	"Add Loan": "Kredit hinzufügen",
	"The page you requested could not be found": "Die Seite wurde nicht gefunden",
	"Location": "Ort",
	"Name": "Name",
	"No entries": "Keine Einträge",
	"New": "Neu",
	"Finance Overview": "Finanzübersicht",
	"Monetary Value": "Geldwerte",
	"Precious Metals": "Edelmetalle",
	"Corporate Investments": "Unternehmensbeteiligungen",
	"Miscellaneous": "Sonstiges",
	"Percent": "Prozent",
	"Total Assets": "Gesamtvermögen",
	"Sum": "Summe",
}
