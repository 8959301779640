import * as React from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';
import {BootstrapContext} from "./BootstrapContext";

export const withBootstrap = (WrappedComponent: any) => {
	class BootstrapComponent extends React.Component<any, any> {
		render() {
			return (
				<BootstrapContext.Consumer>
					{context => <WrappedComponent {...context} {...this.props} />}
				</BootstrapContext.Consumer>
			);
		}
	}

	hoistNonReactStatic(BootstrapComponent, WrappedComponent);
	return BootstrapComponent
}
