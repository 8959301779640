import {Content, UserInfo, Visibility} from "@odania-it/odania-common/dist/CommonModels";

export interface Budget {
	uid: string
	expenditure: BudgetEntry[]
	income: BudgetEntry[]
}

export interface BudgetEntry {
	title: string
	description: string
	amount: number
}

export interface Group {
	slug: string
	title: string
	locale: string
	subtitle: string
	description: string
	image: string
	imageCopyright: string
	owner: UserInfo
	user_count: number
	users: UserInfo[]
	tags: string[]
	visibility: Visibility
}

export interface GroupMessage {
	user: UserInfo
	message: string
	createdAt: string
}

export interface GroupPost extends Content {
	group: string
}

export interface Loan {
	title: string
	amount: number
	interestRate: number
	initialRepayment: number
	startDate: string
	endDate: string
}

export interface RealEstate {
	slug: string
	uid: string
	name: string
	description: string
	address: string
	owned: boolean
	buyDate: string
	purchased: boolean
	purchaseDate: string
	purchasePrice: number
	livingSpace: number
	parkingSpace: number
	brokerFeePercent: number
	notaryFeePercent: number
	landRegisterFeePercent: number
	realEstateTransferTaxPercent: number
	otherCosts: number
	shareOfBuildingInPurchasePricePercent: number
	coldRent: number
	coldRentParkingSpace: number
	coldRentOther: number
	houseAllowance: number
	houseAllowanceApportionable: number
	houseAllowanceNotApportionable: number
	propertyTax: number
	commonPropertyReserve: number
	ownMaintenanceReservePerSqm: number
	imputedLossOfRentPercent: number
	deprecationPercent: number
	increaseInValuePerYearPercent: number
	equity: number
	loans: Loan[]
}

export type TParams = {
	locale: string,
	slug: string,
}

export interface FinanceEntry {
	categorySlug: string
	title: string
	amount: number
}

export interface FinanceRecord {
	slug: string
	entries: FinanceEntry[]
}

export interface FinanceCategory {
	slug: string
	title: string
}

export interface UserData {
	financeCategories: FinanceCategory[]
}

export interface UserJournal {
	createdAt: string
	journalType: UserJournalType.success | UserJournalType.target
	message: string
	title: string
	uid: string
}

export enum UserJournalType {
	success = 'success',
	target = 'target',
}

export interface NumberDictionary {
	[key: string]: number
}
