import React from "react";
import {firebaseAuth, firebaseUiConfig} from "../../Components/Firebase";
import {StyledFirebaseAuth} from "react-firebaseui";
import {BootstrapContextProps} from "../../Components/Bootstrap/BootstrapContext";
import {withBootstrap} from "../../Components/Bootstrap/withBootstrap";
import {Redirect} from "react-router-dom";

class LoginPage extends React.Component<BootstrapContextProps, any> {
	render() {
		if (this.props.user) {
			return <Redirect to={`/${this.props.locale}/protected/projects`}/>
		}

		return (
			<header className="App-header">
				<StyledFirebaseAuth uiConfig={firebaseUiConfig} firebaseAuth={firebaseAuth}/>
			</header>
		)
	}
}

export default withBootstrap(LoginPage)
