import {renderToStaticMarkup} from "react-dom/server";
import {InitializePayload} from "react-localize-redux";

class LocalizeHelper {
	public static localeConfig(): InitializePayload {
		return {
			languages: [
				{name: "German", code: "de"},
				{name: "English", code: "en"},
			],
			options: {renderToStaticMarkup, renderInnerHtml: true}
		};
	}
}

export default LocalizeHelper
