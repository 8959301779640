import Dashboard from "./Pages/Dashboard/Dashboard";
import GroupsPage from "./Pages/Groups/GroupsPage";
import JournalPage from "./Pages/Journal/JournalPage";
import PricingPage from "./Pages/Pricing/PricingPage";
import ShowBudgetPage from "./Pages/Budgets/ShowBudgetPage";
import RealEstatesPage from "./Pages/RealEstates/RealEstatesPage";
import AccountsPage from "./Pages/Accounts/AccountsPage";
import FinanceOverviewPage from "./Pages/Finances/FinanceOverviewPage";

export interface RouteData {
	collapse?: boolean
	path: string
	name: string
	icon: string
	component: any
	layout: string
	mini: string
	state?: string
	views?: RouteData[]
	redirect?: string
}

export const communityMoneyRoutes = [
	{
		path: '/dashboard',
		name: 'Dashboard',
		icon: "tim-icons icon-chart-pie-36",
		component: Dashboard,
		layout: "/protected"
	},
	{
		path: '/groups',
		name: "Groups",
		icon: "tim-icons icon-heart-2",
		component: GroupsPage,
		layout: "/protected"
	},
	{
		path: '/finances',
		name: "Finances",
		icon: "tim-icons icon-molecule-40",
		component: FinanceOverviewPage,
		layout: "/protected"
	},
	{
		path: '/real-estates',
		name: "Real Estates",
		icon: "tim-icons icon-badge",
		component: RealEstatesPage,
		layout: "/protected"
	},
	{
		path: '/accounts',
		name: "Accounts",
		icon: "tim-icons icon-money-coins",
		component: AccountsPage,
		layout: "/protected"
	},
	{
		path: '/budgets',
		name: "Budgets",
		icon: "tim-icons icon-coins",
		component: ShowBudgetPage,
		layout: "/protected"
	},
	{
		path: "/journal",
		name: "Journal",
		icon: "tim-icons icon-notes",
		component: JournalPage,
		layout: "/protected"
	},
	{
		path: "/posts",
		name: "Posts",
		icon: "tim-icons icon-money-coins",
		component: PricingPage,
		layout: "/protected"
	},
	{
		collapse: true,
		name: "Pages",
		icon: "tim-icons icon-components",
		state: "pagesCollapse",
		views: [
			{
				path: "/pages",
				name: "Pages",
				mini: "PA",
				component: GroupsPage,
				layout: "/protected"
			},
			{
				path: "/pages/faq",
				name: "FAQ",
				mini: "F",
				component: GroupsPage,
				layout: "/protected"
			},
		],
	},
	{
		path: "/pricing",
		name: "Pricing",
		icon: "tim-icons icon-credit-card",
		component: PricingPage,
		layout: "/protected"
	},
] as RouteData[]
