import React from "react";
import {Translate} from "react-localize-redux";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {DateTime} from "luxon";
import {getCategoryOrTagFor} from "@odania-it/odania-common/dist/Utils/Utils";
import {Group, GroupMessage, GroupPost, TParams} from "../../Models";
import {Button, Card, CardBody, Col, Container, Input, Row} from "reactstrap";
import {firestoreDb, retrieveDocumentFromFirestore} from "../../Components/Firebase";
import {withBootstrap} from "../../Components/Bootstrap/withBootstrap";
import LocaleLink from "../../Components/Common/LocaleLink";
import TemplateHelper from "@odania-it/odania-common/dist/Components/Common/TemplateHelper";
import {BootstrapContextProps} from "../../Components/Bootstrap/BootstrapContext";
import {UserInfo, Visibility} from "@odania-it/odania-common/dist/CommonModels";
import {validateMessage} from "../../Components/Utils/MessageUtils";
import DisplayUserInfo from "../../Components/Users/DisplayUserInfo";
import CardAvatar from "../../Components/Users/CardAvatar";
import firebase from 'firebase';

interface GroupsPageState {
	documentFound: boolean
	group: Group
	isLoading: boolean
	message: string
	messages: firebase.firestore.QueryDocumentSnapshot[]
	posts: firebase.firestore.QueryDocumentSnapshot[]
}

class GroupPage extends React.Component<BootstrapContextProps & RouteComponentProps<TParams>, GroupsPageState> {
	state = {
		documentFound: false,
		group: {
			slug: '',
			title: '',
			description: '',
			locale: this.props.locale,
			subtitle: '',
			visibility: Visibility.PUBLIC,
			owner: {
				uid: this.props.user?.uid,
				name: this.props.user?.displayName,
				image: this.props.user?.photoURL,
			},
			tags: [] as string[],
			user_count: 1,
			users: [
				{
					uid: this.props.user?.uid,
					name: this.props.user?.displayName,
					image: this.props.user?.photoURL,
				},
			] as UserInfo[],
		} as Group,
		isLoading: true,
		message: '',
		messages: [] as firebase.firestore.QueryDocumentSnapshot[],
		posts: [] as firebase.firestore.QueryDocumentSnapshot[],
	}

	constructor(props: any) {
		super(props);

		this.doSubmitMessage = this.doSubmitMessage.bind(this)
		this.handleSubmitMessage = this.handleSubmitMessage.bind(this)
	}

	handleSubmitMessage(event: any) {
		if (event.key !== 'Enter') {
			return
		}

		this.doSubmitMessage()
	}

	doSubmitMessage() {
		const groupId = this.props.match.params.slug
		let collectionPath = `groups/${groupId}/messages`;
		console.log('Handle submit', collectionPath)
		const newMessage = {
			user: {
				uid: this.props.user?.uid,
				name: this.props.user?.displayName,
				image: this.props.user?.photoURL,
			},
			message: this.state.message,
			createdAt: DateTime.local().toISO(),
		} as GroupMessage
		console.log(newMessage)

		if (validateMessage(this.state.message)) {
			firestoreDb
				.collection(collectionPath)
				.add(newMessage)
				.then(result => {
					console.log('Message saved')
					this.setState({message: ''})
				})
				.catch(err => {
					console.error(err)
				})
		}
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		document.body.scrollTop = 0;

		this.loadData()

		const groupCollectionPath = `groups/${this.props.match.params.slug}`
		this.loadMessages(groupCollectionPath)
		this.loadPosts(groupCollectionPath)
	}

	componentDidUpdate(prevProps: Readonly<BootstrapContextProps & RouteComponentProps<TParams>>, prevState: Readonly<GroupsPageState>, snapshot?: any) {
		if (prevProps.match.params.slug !== this.props.match.params.slug) {
			this.loadData()
		}
	}

	goTo(postSlug: string) {
		this.props.history.push(`/groups/${this.props.match.params.slug}/posts/${postSlug}`)
	}

	loadData() {
		const docId = this.props.match.params.slug
		retrieveDocumentFromFirestore('groups', docId, doc => {
			if (!doc.exists) {
				console.error(`Group does not exist! groups/${docId}`);
				this.setState({documentFound: false, isLoading: false})
				return;
			}

			this.setState({
				documentFound: true,
				group: doc.data() as Group,
				isLoading: false,
			})
		}, err => {
			console.error('ERROR', err)
		})
	}

	loadMessages(groupCollectionPath: string) {
		firestoreDb
			.collection(`${groupCollectionPath}/messages`)
			.orderBy('createdAt', 'desc')
			.limit(25)
			.onSnapshot(snapshot => {
				console.log('loadMessages', snapshot.docs, snapshot.empty)
				this.setState({messages: snapshot.docs})
			})
	}

	loadPosts(groupCollectionPath: string) {
		firestoreDb
			.collection(`${groupCollectionPath}/posts`)
			.orderBy('createdAt', 'desc')
			.limit(25)
			.onSnapshot(snapshot => {
				console.log('loadPosts', snapshot.docs, snapshot.empty)
				this.setState({posts: snapshot.docs})
			})
	}

	render(): JSX.Element {
		const {group} = this.state

		return (
			<div>
				<div className="container">
					<Container justify="center">
						<Row md={8} className="textCenter">
							<Col>
								<h1 className="title">
									{group.title}
								</h1>
								<h4 className="subtitle">
									{group.subtitle}
								</h4>
							</Col>
						</Row>
					</Container>
				</div>
				<div className="main">
					<div className="container">
						<Container justify="center" spacing={3}>
							<Row xs={12} sm={12} md={4}>
								<Col>
									<Card>
										<h3>
											<Translate id="Description"/>
										</h3>

										{group.description}
									</Card>
								</Col>
							</Row>
							<Row xs={12} sm={12} md={4}>
								<Col>
									<Card>
										<Translate id="Members"/>: {group.user_count}
										<br/>
										<Translate id="Creator"/>:
										<DisplayUserInfo user={group.owner}/>
									</Card>
								</Col>
							</Row>
						</Container>

						<h2>
							<Translate id="Messages"/>
						</h2>

						<Container justify="center" spacing={3}>
							<Row>
								<Col>
									{!!this.props.user &&
									<Card>
										<Input
											name="message"
											label={<Translate id="Your message"/>}
											variant="filled"
											onChange={(event) => TemplateHelper.updateField(this, event)}
											onKeyDown={this.handleSubmitMessage}
											value={this.state.message}
											style={{paddingRight: '10px'}}
										/>
										<Button variant="contained" color="primary" onClick={this.doSubmitMessage}>
											<Translate id="Add"/>
										</Button>
									</Card>
									}
									<div className="row">
										{this.state.messages.map((data, idx) => {
											const message = data.data() as GroupMessage

											return (
												<div className="col-md-4" key={idx}>
													<Card profile plain>
														{message.user.image &&
														<CardAvatar profile plain>
															<img src={message.user.image} alt={message.user.name} width={60}/>
														</CardAvatar>
														}
														<CardBody plain>
															<h4 className="card-title">
																{message.user.name}
															</h4>
															<h6 className="card-category card-muted">
																{TemplateHelper.formatDate(message.createdAt, 'nice')}
															</h6>

															<h5 className="card-description">
																{message.message}
															</h5>
														</CardBody>
													</Card>
												</div>
											)
										})}
									</div>
								</Col>
							</Row>
						</Container>

						<h2>
							<Translate id="Posts"/>
						</h2>

						<Container justify="center" spacing={3}>
							{this.state.posts.map((data, idx) => {
								const post = data.data() as GroupPost;

								return (
									<Row xs={12} sm={6} md={6} key={idx}>
										<Col>
											<Card
												raised
												background
												style={{backgroundImage: "url(" + post.imageUrl + ")"}}
											>
												<CardBody background>
													<h6 className="category">
														{getCategoryOrTagFor(post)}
													</h6>
													<LocaleLink to={`protected/groups/${this.props.match.params.slug}/posts/${post.slug}`}>
														<h3 className="cardTitle">
															{post.title}
														</h3>
													</LocaleLink>
													<p className="category">
														{post.shortDescription}
													</p>

													<Button round onClick={() => this.goTo(post.slug)} color="warning">
														<i className="tim-icons icon-align-left-2"/> <Translate id="Read Article"/>
													</Button>
												</CardBody>
											</Card>
										</Col>
									</Row>
								)
							})}
						</Container>
					</div>
				</div>
			</div>
		)
	}
}

export default withBootstrap(withRouter(GroupPage))
