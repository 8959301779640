/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import {Container} from "reactstrap";
// used for making the prop types of this component
import {Translate} from "react-localize-redux";


interface FooterProps {
	default?: boolean
	fluid?: boolean
}

class Footer extends React.Component<FooterProps, any> {
	render() {
		return (
			<footer
				className={"footer" + (this.props.default ? " footer-default" : "")}
			>
				<Container fluid={this.props.fluid}>
					<ul className="nav">
						<li className="nav-item">
							<a className="nav-link" href="https://www.odania-it.com/de/data-protection" target="_blank"
								 rel="noreferrer">
								<Translate id="Data Protection"/>
							</a>
						</li>
						{" "}
						<li className="nav-item">
							<a className="nav-link" href="https://www.odania-it.com/de/imprint" target="_blank" rel="noreferrer">
								<Translate id="Imprint"/>
							</a>
						</li>
					</ul>
					<div className="copyright">
						© {new Date().getFullYear()} by{" "}
						<a href="https://www.odania.com/" target="_blank">
							Mike Petersen Odania IT
						</a>
					</div>
				</Container>
			</footer>
		);
	}
}

export default Footer;
