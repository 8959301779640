import React from "react";
import DisplayJournal from "../../Components/UserJournal/DisplayJournal";

class JournalPage extends React.Component<any, any> {
	render() {
		return <DisplayJournal/>
	}
}

export default JournalPage
