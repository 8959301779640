import React from "react";
import {Table} from "reactstrap";
import LoadingIndicator from "../../Components/Common/LoadingIndicator";
import {firestoreDb} from "../../Components/Firebase";
import {BootstrapContextProps} from "../../Components/Bootstrap/BootstrapContext";
import {withBootstrap} from "../../Components/Bootstrap/withBootstrap";
import firebase from "firebase/app";
import {Translate} from "react-localize-redux";
import LocaleLink from "../../Components/Common/LocaleLink";

interface AccountsPageState {
	accounts: firebase.firestore.QueryDocumentSnapshot[]
	loading: boolean
}

class AccountsPage extends React.Component<BootstrapContextProps, AccountsPageState> {
	state = {
		accounts: [] as firebase.firestore.QueryDocumentSnapshot[],
		loading: true,
	}

	componentDidMount() {
		this.loadData()
	}

	private loadData() {
		firestoreDb
			.collection(`users/${this.props.user?.uid}/accounts`)
			.orderBy('title', 'desc')
			.get()
			.then((snapshot) => {
				const docs = snapshot.empty ? [] as firebase.firestore.QueryDocumentSnapshot[] : snapshot.docs
				this.setState({accounts: docs, loading: false})
			})
	}

	render() {
		if (this.state.loading) {
			return <LoadingIndicator/>
		}

		return (
			<div className="wrapper">
				<div className="main">
					<div className="section section-white">
						<h1>
							<Translate id="Accounts"/>
						</h1>

						<LocaleLink href="/protected/accounts/new">
							<Translate id="New"/>
						</LocaleLink>

						<Table aria-label="simple dark">
							<thead>
								<tr>
									<th>
										<Translate id="Title"/>
									</th>
									<th>
										<Translate id="Balance"/>
									</th>
								</tr>
							</thead>
							<tbody>
								{this.state.accounts.map((doc, idx) => {
									const account = doc.data()
									return (
										<tr key={idx}>
											<tr>
												<LocaleLink to={`protected/accounts/${account.slug}`}>
													{account.title}
												</LocaleLink>
											</tr>
											<tr>
												{account.balance}
											</tr>
										</tr>
									)
								})}
							</tbody>
						</Table>
					</div>
				</div>
			</div>
		)
	}
}

export default withBootstrap(AccountsPage)
