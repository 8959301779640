import React from "react";
import {Link} from "react-router-dom";
import {BootstrapContextProps} from "../Bootstrap/BootstrapContext";
import {withBootstrap} from "../Bootstrap/withBootstrap";

export interface LocaleLinkProps {
	className: string,
	to: string,
}

class LocaleLink extends React.Component<BootstrapContextProps & LocaleLinkProps, void> {
	public static defaultProps = {
		className: '',
	}

	render(): JSX.Element {
		return <Link to={`/${this.props.locale}/${this.props.to}`} className={this.props.className}>
			{this.props.children}
		</Link>
	}
}

export default withBootstrap(LocaleLink)
