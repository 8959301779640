import React from "react";
import FormBuilder, {FormBuilderField} from "../Forms/FormBuilder";
import {Translate} from "react-localize-redux";
import {RealEstate} from "../../Models";
import {Col, Row} from "reactstrap";

export const realEstateManagementInformation = [
	{key: 'coldRent', type: 'number', label: 'Cold Rent'},
	{key: 'coldRentParkingSpace', type: 'number', label: 'Cold Rent Parking Space'},
	{key: 'coldRentOther', type: 'number', label: 'Cold Rent Other'},
	{key: 'houseAllowance', type: 'number', label: 'House Allowance'},
	{key: 'houseAllowanceApportionable', type: 'number', label: 'House Allowance Apportionable'},
	{key: 'houseAllowanceNotApportionable', type: 'number', label: 'House Allowance not Apportionable'},
	{key: 'propertyTax', type: 'number', label: 'Property Tax', initial: 10},
	{key: 'commonPropertyReserve', type: 'number', label: 'Common Property Reserve', initial: 20},
	{key: 'ownMaintenanceReservePerSqm', type: 'number', label: 'Own Maintenance Reserve per sqm', initial: 10},
	{key: 'imputedLossOfRentPercent', type: 'number', label: 'Imputed loss of rent (Percent)', initial: 3},
	{key: 'deprecationPercent', type: 'number', label: 'Deprecation (Percent)', initial: 2},
	{key: 'increaseInValuePerYearPercent', type: 'number', label: 'Increase in value per year (Percent)', initial: 2},
] as FormBuilderField[]

interface RealEstateManagementProps {
	handleChange: (key: string, value: string | boolean | number) => void
	realEstate: RealEstate
}

function RealEstateManagement(props: RealEstateManagementProps) {
	return (
		<Row>
			<Col>
				<h1>
					<Translate id="Management"/>
				</h1>
				<FormBuilder
					fields={realEstateManagementInformation}
					handleChange={props.handleChange}
					obj={props.realEstate}
				/>
			</Col>
		</Row>
	)
}

export default RealEstateManagement
