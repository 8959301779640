import React from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {Loan, RealEstate, TParams} from "../../Models";
import {BootstrapContextProps} from "../../Components/Bootstrap/BootstrapContext";
import {firestoreDb} from "../../Components/Firebase";
import LoadingIndicator from "../../Components/Common/LoadingIndicator";
import {withBootstrap} from "../../Components/Bootstrap/withBootstrap";
import {LocalizeContextProps, Translate, withLocalize} from "react-localize-redux";
import {Button, Col, Container, Nav, NavItem, NavLink, Row} from "reactstrap";
import FormBuilder, {buildInitialData, FormBuilderField} from "../../Components/Forms/FormBuilder";
import RealEstateCost, {realEstateCostInformation} from "../../Components/RealEstates/RealEstateCost";
import RealEstateFinancing, {realEstateFinancingInformation} from "../../Components/RealEstates/RealEstateFinancing";
import RealEstateManagement, {realEstateManagementInformation} from "../../Components/RealEstates/RealEstateManagement";
import update from "immutability-helper";
import RealEstateInformation from "../../Components/RealEstates/RealEstateInformation";

const baseInformation = [
	{key: 'name', type: 'text', label: 'Name'},
	{key: 'description', type: 'multiline', label: 'Description'},
	{key: 'purchased', type: 'switch', label: 'Purchased'},
	{key: 'purchaseDate', type: 'date', label: 'Purchase Date', conditionField: 'purchased'},
	{key: 'address', type: 'text', label: 'Address'},
] as FormBuilderField[]

const initialRealEstate = buildInitialData(baseInformation.concat(realEstateCostInformation)
	.concat(realEstateManagementInformation).concat(realEstateFinancingInformation)) as RealEstate


interface ShowRealEstatePageState {
	loading: boolean
	realEstate: RealEstate
	tabIdx: number
}

class ShowRealEstatePage extends React.Component<BootstrapContextProps & LocalizeContextProps & RouteComponentProps<TParams>, ShowRealEstatePageState> {
	state = {
		loading: true,
		realEstate: {} as RealEstate,
		tabIdx: 0,
	}

	constructor(props: any) {
		super(props);

		this.handleChange = this.handleChange.bind(this)
		this.saveRealEstate = this.saveRealEstate.bind(this)
	}

	loadRealEstate(): void {
		let slug = this.props.match.params.slug;
		if (slug === 'new') {
			this.setState({loading: false, realEstate: initialRealEstate})
			return
		}

		firestoreDb.collection('real-estates')
			.doc(slug)
			.get()
			.then(result => {
				const data = result.data() as RealEstate
				console.log('Retrieved document from firestore', result.id, data)
				this.setState({realEstate: data, loading: false})
			})
			.catch(err => {
				console.error('Error retrieving documents from firestore', err)
			})
	}

	componentDidMount(): void {
		this.loadRealEstate()
	}

	componentDidUpdate(prevProps: Readonly<BootstrapContextProps & RouteComponentProps<TParams>>) {
		if (this.props.match.params.slug !== prevProps.match.params.slug) {
			this.loadRealEstate()
		}
	}

	private handleChange(key: string, value: string | boolean | number | Loan[]) {
		console.log('Handle Change')
		const newData = update(this.state.realEstate, {
			[key]: {$set: value},
		})
		this.setState({realEstate: newData})
	}

	private saveRealEstate(): void {
		const slug = this.props.match.params.slug
		const {realEstate} = this.state
		const uid = this.props.user?.uid
		if (slug === 'new') {
			realEstate.slug = `${uid}-${Date.now()}`
			realEstate.uid = realEstate.uid ? realEstate.uid : uid as string
		}

		console.log('Saving Real Estate', realEstate)
		firestoreDb.collection('real-estates')
			.doc(realEstate.slug)
			.set(realEstate)
			.then((result) => {
				console.log('saveRealEstate', result)

				if (slug !== realEstate.slug) {
					this.props.history.push(`/protected/real-estates/${realEstate.slug}`)
				}

			})
			.catch((err) => {
				console.error('saveRealEstate error', err)
			})
	}

	render(): JSX.Element {
		const {translate} = this.props
		const {realEstate} = this.state

		if (this.state.loading) {
			return <LoadingIndicator/>
		}

		return (
			<Container>
				<Nav tabs={true} defaultValue={this.state.tabIdx}>
					<NavItem>
						<NavLink href="#" onClick={() => this.setState({tabIdx: 0})}>
							<Translate id="Overview"/>
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink href="#" onClick={() => this.setState({tabIdx: 1})}>
							<Translate id="Initial Costs"/>
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink href="#" onClick={() => this.setState({tabIdx: 2})}>
							<Translate id="Management"/>
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink href="#" onClick={() => this.setState({tabIdx: 3})}>
							<Translate id="Financing"/>
						</NavLink>
					</NavItem>
				</Nav>

				<Row>
					<Col>
						{this.state.tabIdx === 0 &&
						<>
							<h1>{translate('Real Estate')}</h1>
							<FormBuilder fields={baseInformation} handleChange={this.handleChange} obj={realEstate}/>
							<Button onClick={this.saveRealEstate} variant="outlined" color="primary">
								{translate('Save')}
							</Button>
						</>
						}

						{this.state.tabIdx === 1 &&
						<RealEstateCost realEstate={realEstate} handleChange={this.handleChange}/>}

						{this.state.tabIdx === 2 &&
						<RealEstateManagement realEstate={realEstate} handleChange={this.handleChange}/>}

						{this.state.tabIdx === 3 &&
						<RealEstateFinancing realEstate={realEstate} handleChange={this.handleChange}/>}
					</Col>
				</Row>
				<Row>
					<Col>
						<RealEstateInformation realEstate={realEstate}/></Col>
				</Row>
			</Container>
		)
	}
}

export default withLocalize(withBootstrap(withRouter(ShowRealEstatePage)))
