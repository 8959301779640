import React from "react";
import {Button, Card, Col, Container, Input, Row, Table} from "reactstrap";
import {Translate} from "react-localize-redux";
import {firestoreDb} from "../../Components/Firebase";
import LoadingIndicator from "../../Components/Common/LoadingIndicator";
import {BootstrapContextProps} from "../../Components/Bootstrap/BootstrapContext";
import {withBootstrap} from "../../Components/Bootstrap/withBootstrap";
import {loadUserData, localeNumberFormat} from "../../Components/Utils/Utils";
import {FinanceEntry, FinanceRecord, NumberDictionary, UserData} from "../../Models";
import update from "immutability-helper";

interface FinanceOverviewPageState {
	finance: FinanceRecord
	loading: boolean
	userData: UserData
}

class FinanceOverviewPage extends React.Component<BootstrapContextProps, FinanceOverviewPageState> {
	private saveTimer: any = null

	state = {
		finance: {} as FinanceRecord,
		loading: true,
		userData: {} as UserData,
	}

	constructor(props: any) {
		super(props);

		this.addEntry = this.addEntry.bind(this)
		this.calculateAmounts = this.calculateAmounts.bind(this)
		this.updateEntryTitle = this.updateEntryTitle.bind(this)
		this.updateEntryValue = this.updateEntryValue.bind(this)
	}

	componentDidMount() {
		this.loadData()
	}

	private loadData() {
		const collectionPath = `users/${this.props.user?.uid}/finances`;
		console.log('collectionPath', collectionPath)
		firestoreDb.collection(collectionPath)
			.doc('latest')
			.get()
			.then((snapshot) => {
				const finance = (snapshot.exists ? snapshot.data() : {entries: []}) as FinanceRecord

				loadUserData(this.props.user?.uid as string, this.props.locale)
					.then(userData => {
						this.setState({finance: finance, userData: userData, loading: false})
					})
			})
	}

	private saveFinance() {
		if (this.saveTimer) {
			clearTimeout(this.saveTimer)
		}

		const collectionPath = `users/${this.props.user?.uid}/finances`;
		firestoreDb.collection(collectionPath)
			.doc('latest')
			.set(this.state.finance)
			.then(() => {
				console.log('Saved')
			})
			.catch(err => {
				console.error('Error saving', err);
			})
	}

	private addEntry(categorySlug: string) {
		const newData = update(this.state.finance, {
			entries: {
				$push: [
					{
						title: `Entry ${this.state.finance.entries.length + 1}`,
						categorySlug: categorySlug,
						amount: 0
					} as FinanceEntry,
				]
			}
		})
		this.setState({finance: newData})
	}

	private updateEntryTitle(idx: number, value: string) {
		const newData = update(this.state.finance, {
			entries: {
				[idx]: {
					title: {$set: value},
				}
			}
		})
		this.setState({finance: newData})
		this.startSaveTimer()
	}

	private updateEntryValue(idx: number, value: number) {
		const newData = update(this.state.finance, {
			entries: {
				[idx]: {
					amount: {$set: value},
				}
			}
		})
		this.setState({finance: newData})
		this.startSaveTimer()
	}

	private displayPercent(totalAmount: number, amount: number) {
		if (!totalAmount || totalAmount === 0 || amount === 0) {
			return 0
		}

		return localeNumberFormat.format(amount / totalAmount * 100)
	}

	private calculateAmounts(entries: FinanceEntry[]) {
		let totalAmount = 0;
		const amountPerCategory = {} as NumberDictionary

		for (const financeCategory of this.state.userData.financeCategories) {
			amountPerCategory[financeCategory.slug] = 0
		}

		for (const entry of entries) {
			totalAmount += entry.amount
			amountPerCategory[entry.categorySlug] += entry.amount
		}

		return {
			amountPerCategory: amountPerCategory,
			totalAmount: totalAmount,
		}
	}

	private startSaveTimer() {
		if (this.saveTimer) {
			clearTimeout(this.saveTimer)
		}

		this.saveTimer = setTimeout(() => {
			this.saveFinance()
		}, 4000)
	}

	render() {
		if (this.state.loading) {
			return <LoadingIndicator/>
		}

		const amounts = this.calculateAmounts(this.state.finance.entries)
		const totalAmount = amounts.totalAmount
		return (
			<Container>
				<Row>
					<Col>
						<h1>
							<Translate id="Finance Overview"/>
						</h1>
					</Col>
				</Row>

				<Row>
					{this.state.userData.financeCategories.map((financeCategory, idx) => {
						return (
							<Col key={idx} md={6}>
								<Card>
									<h2>
										{financeCategory.title}
									</h2>

									<Table>
										<thead>
										<tr>
											<th>
												<Translate id="Title"/>
											</th>
											<th>
												<Translate id="Amount"/>
											</th>
											<th>
												<Translate id="Percent"/>
											</th>
										</tr>
										</thead>
										<tbody>
										{this.state.finance.entries.map((entry, idx) => {
											if (entry.categorySlug !== financeCategory.slug) {
												return null
											}

											return (
												<tr key={idx}>
													<td>
														<Input
															type="text"
															value={entry.title}
															onChange={(e) => this.updateEntryTitle(idx, e.target.value)}
														/>
													</td>
													<td>
														<Input
															type="number"
															value={entry.amount}
															onChange={(e) => this.updateEntryValue(idx, Number(e.target.value))}
														/>
													</td>
													<td>
														{this.displayPercent(totalAmount, entry.amount)}%
													</td>
												</tr>
											)
										})}
										<tr>
											<th>
												<Translate id="Sum"/>
											</th>
											<th>
												{localeNumberFormat.format(amounts.amountPerCategory[financeCategory.slug])}€
											</th>
											<th>
												{this.displayPercent(totalAmount, amounts.amountPerCategory[financeCategory.slug])}%
											</th>
										</tr>
										</tbody>
									</Table>

									<Button onClick={() => this.addEntry(financeCategory.slug)} className="btn btn-primary">
										<Translate id="Add"/>
									</Button>
								</Card>
							</Col>
						)
					})}
				</Row>
				<Row>
					<Col>
						<Translate id="Total Assets"/>: {localeNumberFormat.format(totalAmount)}€

						<br/>
						<Button onClick={() => this.saveFinance()} className="btn btn-primary">
							<Translate id="Save"/>
						</Button>
					</Col>
				</Row>
			</Container>
		)
	}
}

export default withBootstrap(FinanceOverviewPage)
