import React from "react";

import {Translate} from "react-localize-redux";
import {withRouter} from "react-router-dom";
import {RouteComponentProps} from "react-router";
import {Group, TParams} from "../../Models";
import {Button, Container} from "reactstrap";
import DisplayGroupGridItems from "./DisplayGroupGridItems";

interface GroupSectionPillsProps {
	loadNext: () => void
	loadPrevious: () => void
	groups: Group[]
}

class GroupSectionPills extends React.Component<GroupSectionPillsProps & RouteComponentProps<TParams>, any> {
	render(): JSX.Element {
		const {groups} = this.props

		return (
			<div className="section">
				<Container>
					<DisplayGroupGridItems groups={groups}/>
				</Container>
				<Container>
					<Button onClick={this.props.loadPrevious} className="buttons">
						<Translate id="Previous"/>
					</Button>
					<Button onClick={this.props.loadNext} className="buttons">
						<Translate id="Next"/>
					</Button>
				</Container>
			</div>
		);
	}
}

export default withRouter(GroupSectionPills)
