import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';

// Configure Firebase.
import {firebaseConfig} from "../../config";

firebase.initializeApp(firebaseConfig)
firebase.analytics()

export const firebaseAuth = firebase.auth()
export const firestoreDb = firebase.firestore()

// Configure FirebaseUI.
export const firebaseUiConfig = {
	// Popup signin flow rather than redirect flow.
	signInFlow: 'popup',
	// Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
	signInSuccessUrl: '/signedIn',
	// We will display Google and Facebook as auth providers.
	signInOptions: [
		firebase.auth.GoogleAuthProvider.PROVIDER_ID,
		firebase.auth.FacebookAuthProvider.PROVIDER_ID,
		{
			provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
			requireDisplayName: true,
		},
	]
}

export const convertFromFirestoreToDocuments = (documents: firebase.firestore.QueryDocumentSnapshot[]): firebase.firestore.DocumentData[] => {
	const result = [] as firebase.firestore.DocumentData[]
	for (const doc of documents) {
		result.push(doc.data())
	}

	return result
}

export const retrieveDocumentFromFirestore = (collectionPath: string, docId: string,
																							foundCallback: (doc: firebase.firestore.DocumentSnapshot) => void,
																							notFoundCallback: (err: Error) => void,
): void => {
	console.log(`retrieveDocumentFromFirestore ${collectionPath} with id ${docId}`)
	firestoreDb
		.collection(collectionPath)
		.doc(docId)
		.get()
		.then(document => {
			foundCallback(document);
		})
		.catch(err => {
			console.error('Error loading document', err);
			notFoundCallback(err)
		})
}

export type FirestoreDocumentsResult = {
	documents: firebase.firestore.DocumentData[]
}

export const queryForDocuments = (
	query: firebase.firestore.Query,
	foundCallback: (result: FirestoreDocumentsResult) => void,
	notFoundCallback: (err: Error) => void,
): void => {
	query.get()
		.then((documents: firebase.firestore.QuerySnapshot) => {
			if (documents.empty) {
				notFoundCallback(new Error(`No documents found`))
				return
			}

			const result = {
				documents: [] as firebase.firestore.DocumentData[],
			}
			for (const doc of documents.docs) {
				result.documents.push(doc.data())
			}

			console.log('############## documents', result)
			foundCallback(result);
		})
		.catch(err => {
			console.error('Error loading documents', err)
			notFoundCallback(err)
		})
}
