import React from "react";
import PropTypes from "prop-types";

export default function CardAvatar(props: any) {
	const {
		children,
		className,
		plain,
		profile,
		testimonial,
		testimonialFooter,
		...rest
	} = props;
	return (
		<div {...rest}>
			{children}
		</div>
	);
}

CardAvatar.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	profile: PropTypes.bool,
	plain: PropTypes.bool,
	testimonial: PropTypes.bool,
	testimonialFooter: PropTypes.bool
};
