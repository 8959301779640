import * as React from "react";
import {Context} from "react";
import firebase from 'firebase/app';

export type BootstrapContextHome = {
	body: string
	displayLatestPosts: boolean
	title: string
}

export type BootstrapContextProps = {
	bootstrapFinished: boolean
	changeLocale: (locale: string) => void
	locale: string
	languages: string[]
	user: firebase.User | null
	pageTitle: string
}

export const BootstrapContext: Context<BootstrapContextProps> = React.createContext({} as BootstrapContextProps)
