import * as React from "react";
import {BootstrapContext, BootstrapContextProps} from "./BootstrapContext";
import {LocalizeContextProps, withLocalize} from "react-localize-redux";
import update from "immutability-helper";
import firebase from "firebase/app";
import {withRouter} from "react-router-dom";
import {RouteComponentProps} from "react-router";
import * as translationsDe from "../../Translations/de";
import * as translationsEn from "../../Translations/en";
import LocalizeHelper from "./LocalizeHelper";
import {TParams} from "../../Models";
import {firebaseAuth} from "../Firebase";

export interface BootstrapProviderState {
	bootstrapLoaded: boolean,
	userLoaded: boolean,
	locale: string,
	bootstrap: BootstrapContextProps,
}

class BootstrapProvider extends React.Component<LocalizeContextProps & RouteComponentProps<TParams>, BootstrapProviderState> {
	private unregisterAuthObserver: firebase.Unsubscribe | null = null

	state = {
		locale: '',
		bootstrapLoaded: false,
		userLoaded: false,
		bootstrap: {
			bootstrapFinished: false,
			changeLocale: this.changeLocale,
			locale: 'de',
			user: null,
			languages: ['de', 'en'] as string[],
			pageTitle: 'Odania Hosting',
		} as BootstrapContextProps,
	};

	constructor(props: LocalizeContextProps & RouteComponentProps<TParams>) {
		super(props)

		this.props.initialize(LocalizeHelper.localeConfig())
		this.props.addTranslationForLanguage(translationsDe.translations, 'de')
		this.props.addTranslationForLanguage(translationsEn.translations, 'en')

		this.changeLocale = this.changeLocale.bind(this)
	}

	changeLocale(locale: string) {
		console.log(`Change locale to ${locale}`)
		this.props.history.push(locale)
	}

	isValidLocale(locale: string) {
		for (const language of this.props.languages) {
			if (language.code === locale) {
				return true
			}
		}

		return false
	}

	loadBootstrap(locale: string): void {
		if (!locale || !this.isValidLocale(locale)) {
			console.error('No locale provided in loadBootstrap');
			locale = 'de'
		}

		this.props.setActiveLanguage(locale)

		const newData = update(this.state.bootstrap, {
			changeLocale: {$set: this.changeLocale},
		})

		this.setState({bootstrap: newData, bootstrapLoaded: true}, () => {
			this.updateBootstrapState(this.state.bootstrapLoaded, true)
		})
	}

	componentDidMount(): void {
		this.loadUser()

		const locale = this.props.match.params.locale;
		this.loadBootstrap(locale)
	}

	updateBootstrapState(bootstrapLoaded: boolean, userLoaded: boolean) {
		const newData = update(this.state.bootstrap, {
			bootstrapFinished: {$set: bootstrapLoaded && userLoaded},
		})
		console.log('Is Bootstrap finished', bootstrapLoaded, userLoaded, newData.bootstrapFinished)
		this.setState({bootstrapLoaded: bootstrapLoaded, userLoaded: userLoaded, bootstrap: newData})
	}

	componentDidUpdate() {
		const locale = this.props.match.params.locale

		if (locale !== undefined && locale !== this.state.locale) {
			console.log(`Locale changed from ${this.state.locale} to ${locale}`);
			this.setState({locale: locale}, () => {
				if (locale) {
					this.loadBootstrap(locale)
				}
			})
		}
	}

	loadUser(): void {
		this.unregisterAuthObserver = firebaseAuth.onAuthStateChanged((user) => {
				this.updateBootstrapState(this.state.bootstrapLoaded, true)

				if (user === null) {
					return
				}

				console.log('userData', user.uid, user.metadata);
				const newData = update(this.state, {
					bootstrap: {
						user: {$set: user},
					},
					userLoaded: {$set: true},
				})
				this.setState(newData)
			}
		)
	}

	render() {
		const contextProps = this.state;

		return (
			<BootstrapContext.Provider value={contextProps.bootstrap}>
				{this.props.children}
			</BootstrapContext.Provider>
		);
	}
}

export default withRouter(withLocalize(BootstrapProvider))
