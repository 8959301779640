import React from "react";
import {Translate} from "react-localize-redux";
import {Table} from "reactstrap";
import {BootstrapContextProps} from "../../Components/Bootstrap/BootstrapContext";
import {firestoreDb} from "../../Components/Firebase";
import {withBootstrap} from "../../Components/Bootstrap/withBootstrap";
import LocaleLink from "../../Components/Common/LocaleLink";
import {RealEstate} from "../../Models";
import firebase from "firebase/app";
import {localeNumberFormat} from "../../Components/Utils/Utils";

interface RealEstatesPageState {
	realEstates: firebase.firestore.QueryDocumentSnapshot[]
}

class RealEstatesPage extends React.Component<BootstrapContextProps, RealEstatesPageState> {
	state = {
		realEstates: [] as firebase.firestore.QueryDocumentSnapshot[],
	}

	componentDidMount(): void {
		this.loadRealEstates()
	}

	loadRealEstates(): void {
		console.log('Retrieving real estates for user', this.props.user)
		firestoreDb.collection('real-estates')
			.where('uid', '==', this.props.user?.uid)
			.orderBy('createdAt', 'desc')
			.limit(10)
			.onSnapshot(snapshot => {
				if (snapshot.empty) {
					return
				}

				this.setState({realEstates: snapshot.docs})
			})
	}

	render(): JSX.Element {
		return (
			<div className="wrapper">
				<div className="main">
					<div className="section section-white">
						<h1>
							<Translate id="Real Estates"/>
						</h1>

						<LocaleLink to="real-estates/new" className="btn btn-primary">
							<Translate id="New"/>
						</LocaleLink>

						<Table aria-label="simple dark">
							<thead>
							<tr>
								<th>
									<Translate id="Name"/>
								</th>
								<th>
									<Translate id="Owned"/>
								</th>
								<th>
									<Translate id="Location"/>
								</th>
								<th>
									<Translate id="Purchase Price"/>
								</th>
							</tr>
							</thead>
							<tbody>
							{this.state.realEstates.map((doc, idx) => {
								const realEstate = doc.data() as RealEstate
								return (
									<tr key={idx}>
										<td>
											<LocaleLink to={`protected/real-estates/${doc.id}`}>
												{realEstate.name}
											</LocaleLink>
										</td>
										<td>
											{realEstate.owned ? <Translate id="Yes"/> : <Translate id="No"/>}
										</td>
										<td>
											{realEstate.address}
										</td>
										<td>
											{localeNumberFormat.format(realEstate.purchasePrice)}€
										</td>
									</tr>
								)
							})}
							{this.state.realEstates.length === 0 &&
							<tr>
								<td colSpan={4}>
									<Translate id="No entries"/>
								</td>
							</tr>
							}
							</tbody>
						</Table>
					</div>
				</div>
			</div>
		)
	}
}

export default withBootstrap(RealEstatesPage)
