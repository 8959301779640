import React from "react";
import {calculateCostInformation} from "./RealEstateInformationCalculation";
import {Table} from "reactstrap";
import {LocalizeContextProps, Translate, withLocalize} from "react-localize-redux";
import {localeNumberFormat} from "../Utils/Utils";

interface RealEstateCostInformationProps {
	realEstate: any
}

class RealEstateInformation extends React.Component<RealEstateCostInformationProps & LocalizeContextProps, any> {
	render() {
		const {realEstate, translate} = this.props
		const costInformation = calculateCostInformation(realEstate)

		return (
			<>
				<h2>
					<Translate id="Overview"/>
				</h2>

				<Table>
					<tbody>
					<tr>
						<td>
							{translate('Purchase Price')}
						</td>
						<td>
							{localeNumberFormat.format(realEstate.purchasePrice)}€
						</td>
						<td>
							{translate('Cost per qm')}: {localeNumberFormat.format(costInformation.costPerQm)}€
						</td>
					</tr>

					<tr>
						<td>
							{translate('Broker Fee')}
						</td>
						<td>
							{localeNumberFormat.format(realEstate.brokerFeePercent)}%
						</td>
						<td>
							{localeNumberFormat.format(costInformation.brokerFee)}€
						</td>
					</tr>

					<tr>
						<td>
							{translate('Notary Fee')}
						</td>
						<td>
							{localeNumberFormat.format(realEstate.notaryFeePercent)}%
						</td>
						<td>
							{localeNumberFormat.format(costInformation.notaryFee)}€
						</td>
					</tr>

					<tr>
						<td>
							{translate('Land Register Fee')}
						</td>
						<td>
							{localeNumberFormat.format(realEstate.landRegisterFeePercent)}%
						</td>
						<td>
							{localeNumberFormat.format(costInformation.landRegisterFee)}€
						</td>
					</tr>

					<tr>
						<td>
							{translate('Real Estate Transfer Tax')}
						</td>
						<td>
							{localeNumberFormat.format(realEstate.realEstateTransferTaxPercent)}%
						</td>
						<td>
							{localeNumberFormat.format(costInformation.realEstateTransferTax)}€
						</td>
					</tr>

					<tr>
						<td>
							{translate('Other Costs')}
						</td>
						<td>
							{localeNumberFormat.format(costInformation.otherCostsPercent)}%
						</td>
						<td>
							{localeNumberFormat.format(realEstate.otherCosts)}€
						</td>
					</tr>

					<tr>
						<td>
							{translate('Additional costs')}
						</td>
						<td>
							{localeNumberFormat.format(costInformation.additionalCostsPercent)}%
						</td>
						<td>
							{localeNumberFormat.format(costInformation.additionalCosts)}€
						</td>
					</tr>

					<tr>
						<td>
							<b>{translate('Total costs')}</b>
						</td>
						<td>
							<b>{localeNumberFormat.format(costInformation.totalCostsPercent)}%</b>
						</td>
						<td>
							<b>{localeNumberFormat.format(costInformation.totalCosts)}€</b>
						</td>
					</tr>
					</tbody>
				</Table>

				<h2>{translate('Cashflow')}</h2>
				<Table>
					<tr>
						<td>
							{translate('Warm rent (per Month)')}
						</td>
						<td>
							{localeNumberFormat.format(costInformation.warmRentPerMonth)}€
						</td>
						<td>
							{localeNumberFormat.format(costInformation.warmRentPerMonth * 12)}€ {translate('per year')}
						</td>
					</tr>
					<tr>
						<td>
							- {translate('Total management costs')}
						</td>
						<td>
							{localeNumberFormat.format(-costInformation.totalManagementCosts)}€
						</td>
						<td>
							{localeNumberFormat.format(-costInformation.totalManagementCosts * 12)}€ {translate('per year')}
						</td>
					</tr>
					<tr>
						<td>
							- {translate('Total interest')}
						</td>
						<td>
							{localeNumberFormat.format(-costInformation.loanInformation.totalInterestPerMonth)}€
						</td>
						<td>
							{localeNumberFormat.format(-costInformation.loanInformation.totalInterestPerMonth * 12)}€ {translate('per year')}
						</td>
					</tr>
					<tr>
						<td>
							- {translate('Total repayment')}
						</td>
						<td>
							{localeNumberFormat.format(-costInformation.loanInformation.totalRepaymentPerMonth)}€
						</td>
						<td>
							{localeNumberFormat.format(-costInformation.loanInformation.totalRepaymentPerMonth * 12)}€ {translate('per year')}
						</td>
					</tr>
					<tr>
						<td>
							<b>{translate('Cashflow before tax')}</b>
						</td>
						<td>
							<b>{localeNumberFormat.format(costInformation.cashflowBeforeTax)}€</b>
						</td>
						<td>
							<b>{localeNumberFormat.format(costInformation.cashflowBeforeTax * 12)}€ {translate('per year')}</b>
						</td>
					</tr>
					<tr>
						<td>
							{translate('Taxes')}
						</td>
						<td>
							{localeNumberFormat.format(costInformation.taxes)}€
						</td>
						<td>
							{localeNumberFormat.format(costInformation.taxes * 12)}€ {translate('per year')}
						</td>
					</tr>
					<tr>
						<td>
							<b>{translate('Cashflow after tax')}</b>
						</td>
						<td>
							<b>{localeNumberFormat.format(costInformation.cashflowAfterTax)}€</b>
						</td>
						<td>
							<b>{localeNumberFormat.format(costInformation.cashflowAfterTax * 12)}€ {translate('per year')}</b>
						</td>
					</tr>
				</Table>

				<h2>{translate('Performance')}</h2>
				<Table>
					<tr>
						<td>
							{translate('Increase in value per year')}
						</td>
						<td>
							{localeNumberFormat.format(costInformation.wealthGrowth)}€
						</td>
					</tr>
					<tr>
						<td>
							{translate('Return to equity')}
						</td>
						<td>
							{costInformation.returnToEquity === -1 ? 'n/a' : localeNumberFormat.format(costInformation.returnToEquity)}%
						</td>
					</tr>
				</Table>

				<h2>{translate('Rental Fee')}</h2>
				<Table>
					<tbody>
						<tr>
							<td>
								{translate('Cold rent (per Month)')}
							</td>
							<td>
								{localeNumberFormat.format(costInformation.coldRentPerMonth)}€
							</td>
							<td>
								{localeNumberFormat.format(costInformation.coldRentPerYear)}€ {translate('per year')}
							</td>
						</tr>
						<tr>
							<td>
								{translate('Gross rental yield')}
							</td>
							<td>
								{localeNumberFormat.format(costInformation.coldRentPerYear / Number(realEstate.purchasePrice) * 100)}%
							</td>
							<td>
							</td>
						</tr>
						<tr>
							<td>
								{translate('Factor')}
							</td>
							<td>
								{localeNumberFormat.format(Number(realEstate.purchasePrice) / costInformation.coldRentPerYear)}
							</td>
							<td>
							</td>
						</tr>
						<tr>
							<td>
								{translate('Net rental yield')}
							</td>
							<td>
								{localeNumberFormat.format(costInformation.netRentalYield * 100)}%
							</td>
							<td>
							</td>
						</tr>
						<tr>
							<td>
								{translate('Warm rent (per Month)')}
							</td>
							<td>
								{localeNumberFormat.format(costInformation.warmRentPerMonth)}€
							</td>
							<td>
								{localeNumberFormat.format(costInformation.warmRentPerMonth * 12)}€ {translate('per year')}
							</td>
						</tr>
					</tbody>
				</Table>

				<h2>{translate('Reserves')}</h2>
				<Table>
					<tbody>
						<tr>
							<td>
								{translate('Recommended Maintenance Reserve')}
							</td>
							<td>
								{localeNumberFormat.format(costInformation.recommendedMaintenanceReserve)}€
							</td>
							<td>
								Peterrsche Formel
							</td>
						</tr>
						<tr>
							<td>
								{translate('Own Maintenance Reserve')}
							</td>
							<td>
								{localeNumberFormat.format(costInformation.ownMaintenanceReserve)}€
							</td>
							<td>

							</td>
						</tr>
						<tr>
							<td>
								{translate('Imputed loss of rent')}
							</td>
							<td>
								{localeNumberFormat.format(costInformation.imputedLossOfRent)}€
							</td>
							<td>

							</td>
						</tr>
						<tr>
							<td>
								{translate('Total Apportionable')}
							</td>
							<td>
								{localeNumberFormat.format(costInformation.totalApportionable)}€
							</td>
							<td>

							</td>
						</tr>
						<tr>
							<td>
								{translate('Total not Apportionable')}
							</td>
							<td>
								{localeNumberFormat.format(costInformation.totalNotApportionable)}€
							</td>
							<td>

							</td>
						</tr>
					</tbody>
				</Table>

				<h2>{translate('Loans')}</h2>
				<Table>
					<tr>
						<td>
							{translate('Total Loan Amount')}
						</td>
						<td>
							{localeNumberFormat.format(costInformation.loanInformation.totalAmount)}€
						</td>
					</tr>
					<tr>
						<td>
							{translate('Equity')}
						</td>
						<td>
							{localeNumberFormat.format(realEstate.equity)}€
						</td>
					</tr>
					<tr>
						<td>
							{translate('Total interest')}
						</td>
						<td>
							{localeNumberFormat.format(costInformation.loanInformation.totalInterestPerMonth)}€
						</td>
					</tr>
					<tr>
						<td>
							{translate('Total repayment')}
						</td>
						<td>
							{localeNumberFormat.format(costInformation.loanInformation.totalRepaymentPerMonth)}€
						</td>
					</tr>
				</Table>

				<h2>{translate('Taxes')}</h2>
				<Table>
					<tr>
						<td>
							{translate('Warm rent (per Month)')}
						</td>
						<td>
							{localeNumberFormat.format(costInformation.warmRentPerMonth)}€
						</td>
						<td>
							{localeNumberFormat.format(costInformation.warmRentPerMonth * 12)}€ {translate('per year')}
						</td>
					</tr>
					<tr>
						<td>
							- {translate('Management costs without Reserves')}
						</td>
						<td>
							- {localeNumberFormat.format(costInformation.managementCostsWithoutReserves)}€
						</td>
						<td>
							- {localeNumberFormat.format(costInformation.managementCostsWithoutReserves * 12)}€ {translate('per year')}
						</td>
					</tr>
					<tr>
						<td>
							- {translate('Total interest')}
						</td>
						<td>
							- {localeNumberFormat.format(costInformation.loanInformation.totalInterestPerMonth)}€
						</td>
						<td>
							- {localeNumberFormat.format(costInformation.loanInformation.totalInterestPerMonth * 12)}€ {translate('per year')}
						</td>
					</tr>
					<tr>
						<td>
							- {translate('Deprecation')}
						</td>
						<td>
							- {localeNumberFormat.format(costInformation.deprecationPerYear / 12)}€
						</td>
						<td>
							- {localeNumberFormat.format(costInformation.deprecationPerYear)}€ {translate('per year')}
						</td>
					</tr>
					<tr>
						<td>
							<b>{translate('Taxable Cashflow')}</b>
						</td>
						<td>
							<b>{localeNumberFormat.format(costInformation.taxableCashflow)}€</b>
						</td>
						<td>
							<b>{localeNumberFormat.format(costInformation.taxableCashflow * 12)}€ {translate('per year')}</b>
						</td>
					</tr>
					<tr>
						<td>
							{translate('Personal Marginal Tax Rate')}
						</td>
						<td>
							{localeNumberFormat.format(costInformation.personalMarginalTaxRate)}%
						</td>
						<td>
						</td>
					</tr>
					<tr>
						<td>
							<b>{translate('Taxes')}</b>
						</td>
						<td>
							<b>{localeNumberFormat.format(costInformation.taxes)}€</b>
						</td>
						<td>
							<b>{localeNumberFormat.format(costInformation.taxes * 12)}€ {translate('per year')}</b>
						</td>
					</tr>
				</Table>
			</>
		)
	}
}

export default withLocalize(RealEstateInformation)
