import React from "react";
import {Card, Col, Row} from "reactstrap";
import BreadcrumbAndNotifications from "../Common/BreadcrumbAndNotifications";
import {Translate} from "react-localize-redux";
import NewJournalEntry from "./NewJournalEntry";
import LastJournalEntries from "./LastJournalEntries";

class DisplayJournal extends React.Component<any, any> {
	render() {
		return (
			<>
				<Row>
					<Col>
						<h2>
							<Translate id="Journal"/>
						</h2>

						<NewJournalEntry journalType="success"/>
					</Col>
				</Row>
				<Row>
					<Col>
						<Card>
							<h2>
								<Translate id="Your success journal"/>
							</h2>

							<LastJournalEntries journalType="success"/>
						</Card>
					</Col>
					<Col>
						<Card>
							<h2>
								<Translate id="Your wishes and targets"/>
							</h2>

							<LastJournalEntries journalType="target"/>
						</Card>
					</Col>
				</Row>
			</>
		)
	}
}

export default DisplayJournal
