import React from "react";
import {Route, Switch, withRouter} from "react-router-dom";
import {BootstrapContextProps} from "./Components/Bootstrap/BootstrapContext";
import {withBootstrap} from "./Components/Bootstrap/withBootstrap";
import LoadingIndicator from "./Components/Common/LoadingIndicator";
import LoginPage from "./Pages/Auth/LoginPage";
import NotFound from "./Pages/Errors/NotFound";
import EditGroupPage from "./Pages/Groups/EditGroupPage";
import GroupPage from "./Pages/Groups/GroupPage";
import RedirectToLocale from "./Components/Common/RedirectToLocale";
import AdminNavbar from "./Components/Common/AdminNavbar";
import PerfectScrollbar from "perfect-scrollbar";
import NotificationAlert from "react-notification-alert";
import FixedPlugin from "./Components/FixedPlugin/FixedPlugin";
import Sidebar from "./Components/Common/Sidebar";
import Footer from "./Components/Common/Footer";
import {RouteComponentProps} from "react-router";
import {communityMoneyRoutes, RouteData} from "./communityMoneyRoutes";
import ShowRealEstatePage from "./Pages/RealEstates/ShowRealEstatePage";
import ShowAccountPage from "./Pages/Accounts/ShowAccountPage";

interface RouterAppState {
	activeColor: "primary" | "blue" | "green" | "orange" | "red"
	sidebarMini: boolean
	opacity: number
	sidebarOpened: boolean
}

let ps: any = undefined

class RouterApp extends React.Component<BootstrapContextProps & RouteComponentProps, RouterAppState> {
	private mainPanel = React.createRef<HTMLDivElement>()
	private notificationAlert = React.createRef<any>()

	state = {
		activeColor: "blue" as "primary" | "blue" | "green" | "orange" | "red",
		sidebarMini: true,
		opacity: 0,
		sidebarOpened: false
	}

	constructor(props: any) {
		super(props);

		this.showNavbarButton = this.showNavbarButton.bind(this);
	}

	componentDidMount() {
		if (navigator.platform.indexOf("Win") > -1) {
			document.documentElement.classList.add("perfect-scrollbar-on");
			document.documentElement.classList.remove("perfect-scrollbar-off");

			ps = new PerfectScrollbar(this.mainPanel.current!);
			this.mainPanel.current!.addEventListener(
				"ps-scroll-y",
				this.showNavbarButton
			);
			let tables = document.querySelectorAll(".table-responsive");
			for (let i = 0; i < tables.length; i++) {
				ps = new PerfectScrollbar(tables[i]);
			}
		}
		window.addEventListener("scroll", this.showNavbarButton);
	}

	componentWillUnmount() {
		if (navigator.platform.indexOf("Win") > -1) {
			ps.destroy();
			document.documentElement.classList.add("perfect-scrollbar-off");
			document.documentElement.classList.remove("perfect-scrollbar-on");

			this.mainPanel.current!.removeEventListener(
				"ps-scroll-y",
				this.showNavbarButton
			);
		}
		window.removeEventListener("scroll", this.showNavbarButton);
	}

	componentDidUpdate(e: any) {
		if (e.location.pathname !== e.history.location.pathname) {
			if (navigator.platform.indexOf("Win") > -1) {
				let tables = document.querySelectorAll(".table-responsive");
				for (let i = 0; i < tables.length; i++) {
					ps = new PerfectScrollbar(tables[i]);
				}
			}
			document.documentElement.scrollTop = 0;
			document.scrollingElement!.scrollTop = 0;
			this.mainPanel.current!.scrollTop = 0;
		}
	}

	showNavbarButton = () => {
		if (
			document.documentElement.scrollTop > 50 ||
			document.scrollingElement!.scrollTop > 50 ||
			this.mainPanel.current!.scrollTop > 50
		) {
			this.setState({opacity: 1});
		} else if (
			document.documentElement.scrollTop <= 50 ||
			document.scrollingElement!.scrollTop <= 50 ||
			this.mainPanel.current!.scrollTop <= 50
		) {
			this.setState({opacity: 0});
		}
	};

	getRoutes = (routes: RouteData[]): any => {
		return routes.map((prop, key) => {
			if (prop.collapse) {
				return this.getRoutes(prop.views as RouteData[]);
			}

			return (
				<Route
					path={`/:locale${prop.layout}${prop.path}`}
					component={prop.component}
					key={key}
				/>
			);
		});
	};

	getActiveRoute = (routes: RouteData[]): any => {
		let activeRoute = "Default Brand Text";
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				let collapseActiveRoute = this.getActiveRoute(routes[i].views as RouteData[]);
				if (collapseActiveRoute !== activeRoute) {
					return collapseActiveRoute;
				}
			} else {
				if (
					window.location.pathname.indexOf(
						routes[i].layout + routes[i].path
					) !== -1
				) {
					return routes[i].name;
				}
			}
		}

		return activeRoute;
	};

	handleActiveClick = (color: "primary" | "blue" | "green" | "orange" | "red") => {
		this.setState({activeColor: color});
	};

	handleMiniClick = () => {
		let notifyMessage = "Sidebar mini ";
		if (document.body.classList.contains("sidebar-mini")) {
			this.setState({sidebarMini: false});
			notifyMessage += "deactivated...";
		} else {
			this.setState({sidebarMini: true});
			notifyMessage += "activated...";
		}
		let options = {};
		options = {
			place: "tr",
			message: notifyMessage,
			type: "primary",
			icon: "tim-icons icon-bell-55",
			autoDismiss: 7
		};
		this.notificationAlert.current!.notificationAlert(options);
		document.body.classList.toggle("sidebar-mini");
	};

	toggleSidebar = () => {
		this.setState({
			sidebarOpened: !this.state.sidebarOpened
		});
		document.documentElement.classList.toggle("nav-open");
	};

	closeSidebar = () => {
		this.setState({
			sidebarOpened: false
		});
		document.documentElement.classList.remove("nav-open");
	};

	render() {
		if (!this.props.bootstrapFinished) {
			return <LoadingIndicator/>
		}

		if (!this.props.user) {
			return <LoginPage/>
		}

		return (
			<div className="wrapper">
				<div className="rna-container">
					<NotificationAlert ref={this.notificationAlert}/>
				</div>

				<div
					className="navbar-minimize-fixed"
					style={{opacity: this.state.opacity}}
				>
					<button
						className="minimize-sidebar btn btn-link btn-just-icon"
						onClick={this.handleMiniClick}
					>
						<i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted"/>
						<i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted"/>
					</button>
				</div>
				<Sidebar
					{...this.props as any}
					routes={communityMoneyRoutes}
					activeColor={this.state.activeColor}
					logo={{
						outerLink: "https://www.odania-it.com/",
						text: "Community.Money",
						imgSrc: 'https://www.community.money/assets/community-money-logo.jpg'
					}}
					closeSidebar={this.closeSidebar}
				/>
				<div
					className="main-panel"
					ref={this.mainPanel}
					data-color={this.state.activeColor}
				>
					<AdminNavbar
						{...this.props}
						handleMiniClick={this.handleMiniClick}
						brandText={this.getActiveRoute(communityMoneyRoutes)}
						sidebarOpened={this.state.sidebarOpened}
						toggleSidebar={this.toggleSidebar}
					/>
					<div className="content">
						<Switch>
							<Route path="/:locale/protected/accounts/:slug" component={ShowAccountPage} exact={true}/>
							<Route path="/:locale/protected/groups/:slug/edit" component={EditGroupPage} exact={true}/>
							<Route path="/:locale/protected/groups/:slug" component={GroupPage} exact={true}/>
							<Route path="/:locale/protected/real-estates/:slug" component={ShowRealEstatePage} exact={true}/>

							<Route path="/:locale" component={LoginPage} exact={true}/>
							<Route path="/" component={RedirectToLocale} exact={true}/>

							{this.getRoutes(communityMoneyRoutes)}

							<Route component={NotFound}/>
						</Switch>
					</div>

					{// we don't want the Footer to be rendered on full screen maps page
						this.props.location.pathname.indexOf("full-screen-map") !==
						-1 ? null : (
							<Footer fluid/>
						)}
				</div>
				<FixedPlugin
					activeColor={this.state.activeColor}
					sidebarMini={this.state.sidebarMini}
					handleActiveClick={this.handleActiveClick}
					handleMiniClick={this.handleMiniClick}
				/>
			</div>
		)
	}
}

export default withBootstrap(withRouter(RouterApp))
