import React from "react";
import CardAvatar from "./CardAvatar";
import {Card, CardBody} from "reactstrap";
import {UserInfo} from "@odania-it/odania-common/dist/CommonModels";

interface DisplayUserInfoProps {
	user: UserInfo
	width: number
}

class DisplayUserInfo extends React.Component<DisplayUserInfoProps, any> {
	static defaultProps = {
		width: 100,
	}

	render() {
		const {user} = this.props

		if (!user) {
			return null
		}

		return (
			<Card>
				{user.image &&
				<CardAvatar profile plain>
					<img src={user.image} alt={user.name} width={this.props.width}/>
				</CardAvatar>
				}
				<CardBody plain>
					<h4 className="card-title">{user.name}</h4>
				</CardBody>
			</Card>
		)
	}
}

export default DisplayUserInfo
