import {firestoreDb} from "../Firebase";
import {FinanceCategory, UserData} from "../../Models";

export const localeNumberFormat = new Intl.NumberFormat('de-DE', {maximumFractionDigits: 2, minimumFractionDigits: 2})

interface LocaleCategories {
	[key: string]: FinanceCategory[]
}

const defaultFinanceCategories = {
	de: [
		{
			slug: 'category-1',
			title: 'Geldwerte',
		},
		{
			slug: 'category-2',
			title: 'Edelmetalle',
		},
		{
			slug: 'category-3',
			title: 'Unternehmensbeteiligungen',
		},
		{
			slug: 'category-4',
			title: 'ETF / Fonds',
		},
		{
			slug: 'category-5',
			title: 'Sonstiges',
		},
	] as FinanceCategory[],
	en: [
		{
			slug: 'category-1',
			title: 'Monetary Value',
		},
		{
			slug: 'category-2',
			title: 'Precious Metals',
		},
		{
			slug: 'category-3',
			title: 'Corporate Investments',
		},
		{
			slug: 'category-4',
			title: 'ETF / Fonds',
		},
		{
			slug: 'category-5',
			title: 'Miscellaneous',
		},
	] as FinanceCategory[]
} as LocaleCategories;

export const loadUserData = (uid: string, locale: string): Promise<UserData> => {
	return new Promise((resolve, reject) => {
		firestoreDb.collection('users')
			.doc(uid)
			.get()
			.then(snapshot => {
				const userData = snapshot.exists ? snapshot.data() as UserData : {financeCategories: defaultFinanceCategories[locale]} as UserData
				resolve(userData);
			})
			.catch((err) => reject(err))
	})
}
