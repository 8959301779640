import React from "react";
import {LocalizeContextProps, Translate, withLocalize} from "react-localize-redux";
import {DateTime} from "luxon";
import {BootstrapContextProps} from "../Bootstrap/BootstrapContext";
import {firestoreDb} from "../Firebase";
import {UserJournalType} from "../../Models";
import TemplateHelper from "@odania-it/odania-common/dist/Components/Common/TemplateHelper";
import {withBootstrap} from "../Bootstrap/withBootstrap";
import {Button, Col, Input, Row} from "reactstrap";

interface NewJournalEntryState {
	journalType: string
	message: string
	title: string
}

class NewJournalEntry extends React.Component<BootstrapContextProps & LocalizeContextProps, NewJournalEntryState> {
	state = {
		journalType: 'success',
		message: '',
		title: DateTime.local().toLocaleString(DateTime.DATE_MED),
	}

	constructor(props: BootstrapContextProps & LocalizeContextProps) {
		super(props)

		this.doSubmit = this.doSubmit.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
	}

	handleSubmit(event: any): void {
		if (event.key !== 'Enter') {
			return
		}

		this.doSubmit()
	}

	doSubmit(): void {
		const collectionPath = `users/${this.props.user?.uid}/journal`;
		console.log('Handle submit', collectionPath)
		firestoreDb
			.collection(collectionPath)
			.add({
				journalType: this.state.journalType,
				uid: this.props.user?.uid,
				title: this.state.title,
				message: this.state.message,
				createdAt: DateTime.local().toISO(),
			})
			.then(result => {
				console.log('Result', result)
				this.setState({message: ''})
			})
			.catch(err => {
				console.error(err)
			})
	}

	render(): JSX.Element {
		const {translate} = this.props
		const paddingStyle = {marginRight: '10px'}

		return (
			<form>
				<Row>
					<Col>
						<Input
							name="title"
							label={translate('Title')}
							variant="filled"
							onChange={(event) => TemplateHelper.updateField(this, event)}
							onKeyDown={this.handleSubmit}
							value={this.state.title}
							style={paddingStyle}
						/>
					</Col>
					<Col>
						<Input
							name="message"
							label={translate('Your message')}
							variant="filled"
							onChange={(event) => TemplateHelper.updateField(this, event)}
							onKeyDown={this.handleSubmit}
							value={this.state.message}
							style={paddingStyle}
						/>
					</Col>
					<Col>
						<Input type="select"
									 name="journalType" value={this.state.journalType} variant="filled"
									 onChange={(event) => TemplateHelper.updateField(this, event)}
									 style={paddingStyle}>
							<option value={UserJournalType.success}>
								{translate("Success Journal")}
							</option>
							<option value={UserJournalType.target}>
								{translate("Wishes and goals")}
							</option>
						</Input>
					</Col>
					<Col>
						<Button variant="contained" color="primary" onClick={this.doSubmit}>
							<Translate id="Add"/>
						</Button>
					</Col>
				</Row>
			</form>
		)
	}
}

export default withBootstrap(withLocalize(NewJournalEntry))
