import React from "react";
import FormBuilder, {FormBuilderField} from "../Forms/FormBuilder";
import {RealEstate} from "../../Models";
import {Translate} from "react-localize-redux";
import {Col, Row} from "reactstrap";

export const realEstateCostInformation = [
	{key: 'livingSpace', type: 'number', label: 'Living Space'},
	{key: 'parkingSpace', type: 'number', label: 'Parking Space'},
	{key: 'purchasePrice', type: 'number', label: 'Purchase Price'},
	{key: 'brokerFeePercent', type: 'number', label: 'Broker Fee (Percent)'},
	{key: 'notaryFeePercent', type: 'number', label: 'Notary Fee (Percent)', initial: 1.5},
	{key: 'landRegisterFeePercent', type: 'number', label: 'Land Register Fee (Percent)', initial: 0.5},
	{key: 'realEstateTransferTaxPercent', type: 'number', label: 'Real Estate Transfer Tax (Percent)', initial: 5},
	{key: 'otherCosts', type: 'number', label: 'Other Costs'},
	{
		key: 'shareOfBuildingInPurchasePricePercent',
		type: 'number',
		label: 'Share of building in purchase price (Percent)',
		initial: 75
	},
] as FormBuilderField[]

interface RealEstateCostProps {
	handleChange: (key: string, value: string | boolean | number) => void
	realEstate: RealEstate
}

function RealEstateCost(props: RealEstateCostProps) {
	return (
		<Row>
			<Col>
				<h1>
					<Translate id="Costs"/>
				</h1>
				<FormBuilder
					fields={realEstateCostInformation}
					handleChange={props.handleChange}
					obj={props.realEstate}
				/>
			</Col>
		</Row>
	)
}

export default RealEstateCost
