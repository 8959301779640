import React, {ChangeEvent} from "react";
import {Button, Card, CardBody, Container, Input, Row} from "reactstrap";
import update from "immutability-helper";
import {BootstrapContextProps} from "../../Components/Bootstrap/BootstrapContext";
import {firestoreDb} from "../../Components/Firebase";
import LoadingIndicator from "../../Components/Common/LoadingIndicator";
import {withBootstrap} from "../../Components/Bootstrap/withBootstrap";
import {Translate} from "react-localize-redux";
import {Budget, BudgetEntry} from "../../Models";
import calculatorImage from "../../assets/images/pixabay/calculator-385506_1920.jpg";

const localeNumberFormat = new Intl.NumberFormat('de-DE', {maximumFractionDigits: 2});

interface ShowBudgetPageState {
	loading: boolean
	budget: Budget,
}

class ShowBudgetPage extends React.Component<BootstrapContextProps, ShowBudgetPageState> {
	state = {
		loading: true,
		budget: {
			expenditure: [
				{
					title: 'Miete',
					amount: 600,
					description: '',
				},
				{
					title: 'Nebenkosten (Telefon, Internet, Strom, Gas, Wasser, Müllabfuhr, ...)',
					amount: 60,
					description: '',
				},
				{
					title: 'Kreditraten',
					amount: 0,
					description: '',
				},
				{
					title: 'Steuern',
					amount: 0,
					description: '',
				},
				{
					title: 'Versicherungen',
					amount: 0,
					description: '',
				},
				{
					title: 'Mitgliedsbeiträge',
					amount: 0,
					description: '',
				},
				{
					title: 'Spenden',
					amount: 0,
					description: '',
				},
				{
					title: 'Benzinkosten',
					amount: 0,
					description: '',
				},
				{
					title: 'Hobbies',
					amount: 0,
					description: '',
				},
			] as BudgetEntry[],
			income: [
				{
					title: 'Gehalt',
					amount: 2000,
					description: '',
				},
				{
					title: 'Dividenden',
					amount: 0,
					description: '',
				},
				{
					title: 'Mieteinnahmen',
					amount: 0,
					description: '',
				},
				{
					title: 'Zinsgutschriften',
					amount: 0,
					description: '',
				},
				{
					title: 'Nebeneinkünfte',
					amount: 0,
					description: '',
				},
			] as BudgetEntry[],
		} as Budget,
	}

	constructor(props: BootstrapContextProps) {
		super(props)

		this.addExpenditureRow = this.addExpenditureRow.bind(this)
		this.addIncomeRow = this.addIncomeRow.bind(this)
		this.handleChange = this.handleChange.bind(this)
		this.saveBudget = this.saveBudget.bind(this)
	}

	componentDidMount() {
		this.loadBudget()
	}

	loadBudget(): void {
		firestoreDb.collection(`users/${this.props.user?.uid}/budgets`)
			.doc('own')
			.get()
			.then(result => {
				if (!result.exists) {
					this.setState({loading: false})
					return
				}

				const data = result.data()
				console.log('Retrieved document from firestore', result.id, data)
				this.setState({budget: data as Budget, loading: false})
			})
			.catch(err => {
				console.error('Error retrieving documents from firestore', err)
			})
	}

	saveBudget(): void {
		firestoreDb.collection(`users/${this.props.user?.uid}/budgets`)
			.doc('own')
			.set(this.state.budget)
			.then(result => {
				console.log('saveBudget', result)
			})
			.catch(err => {
				console.error('saveBudget error', err)
			})
	}

	handleChange(event: ChangeEvent<HTMLInputElement>): void {
		const parts = event.target.id.split('-')
		const newValue = update(this.state.budget, {
			[parts[1]]: {
				[parts[2]]: {
					[parts[3]]: {$set: 'amount' === parts[3] ? Number(event.target.value) : event.target.value},
				}
			}
		})

		this.setState({budget: newValue});
	}

	formatNumber(val: number): string {
		return localeNumberFormat.format(val);
	}

	addExpenditureRow(): void {
		const newValue = update(this.state.budget, {
			expenditure: {
				$push: [
					{
						title: `Ausgabe ${this.state.budget.expenditure.length}`,
						amount: 0
					} as BudgetEntry
				]
			}
		});
		this.setState({budget: newValue})
	}

	addIncomeRow(): void {
		const newValue = update(this.state.budget, {
			income: {
				$push: [
					{
						title: `Einnahme ${this.state.budget.income.length}`,
						amount: 0
					} as BudgetEntry
				]
			}
		})
		this.setState({budget: newValue})
	}

	removeLine(key: string, index: number) {
		const newValue = update(this.state.budget, {
			[key]: {
				$splice: [[index, 1]],
			}
		})
		this.setState({budget: newValue})
	}

	// Calculation
	totalExpenditure(): number {
		let result = 0;
		for (let i = 0; i < this.state.budget.expenditure.length; i++) {
			result += Number(this.state.budget.expenditure[i].amount);
		}

		return result;
	}

	totalIncome(): number {
		let result = 0;
		for (let i = 0; i < this.state.budget.income.length; i++) {
			result += Number(this.state.budget.income[i].amount);
		}

		return result;
	}

	render() {
		if (this.state.loading) {
			return <LoadingIndicator/>
		}

		return (
			<div
				className="background-img"
				style={{
					backgroundImage:
						"url(" + calculatorImage + ")"
				}}
			>
				<div className="filter"/>
				<Container>
					<Row>
						<h1 className="title">
							<Translate id="Budget"/>
						</h1>
					</Row>
					<Row>
						<Card className="card-just-text">
							<CardBody>
								<button onClick={this.saveBudget} className="btn btn-primary">
									<Translate id="Save" />
								</button>

								<table>
									<tbody>
									<tr>
										<th colSpan={3} style={{textAlign: "center"}}>
											<h3>
												<Translate id="Revenue"/>
											</h3>
										</th>
									</tr>
									{this.state.budget.income.map((value, index) => {
										return (
											<tr key={`income-row-${index}`}>
												<td>
													<Input id={`calculator-income-${index}-title`} label={<Translate id="Title"/>}
																		 value={this.state.budget.income[index].title} onChange={this.handleChange}/>
												</td>
												<td>
													<Input id={`calculator-income-${index}-amount`} type="number"
																		 label={<Translate id="Amount"/>}
																		 value={this.state.budget.income[index].amount} onChange={this.handleChange}/>
													&euro; <Translate id="per month"/>
												</td>
												<td>
													<Input id={`calculator-income-${index}-description`} label={<Translate id="Description"/>}
																		 value={this.state.budget.income[index].description} onChange={this.handleChange}/>
												</td>
												<td>
													<button onClick={() => this.removeLine('income', index)}>
														<i className="fa fa-minus"/>
													</button>
												</td>
											</tr>
										)
									})}
									<tr>
										<th colSpan={3} style={{textAlign: "center"}}>
											<Button onClick={this.addIncomeRow} color="primary" variant="contained">
												<Translate id="New row"/>
											</Button>
										</th>
									</tr>
									<tr>
										<th colSpan={3} style={{textAlign: "center"}}>
											<h3>
												<Translate id="Expenditure"/>
											</h3>
										</th>
									</tr>
									{this.state.budget.expenditure.map((value, index) => {
										return (
											<tr key={`expenditure-row-${index}`}>
												<td>
													<Input id={`calculator-expenditure-${index}-title`} label={<Translate id="Title"/>}
																		 value={this.state.budget.expenditure[index].title} onChange={this.handleChange}/>
												</td>
												<td>
													<Input id={`calculator-expenditure-${index}-amount`} type="number"
																		 label={<Translate id="Amount"/>}
																		 value={this.state.budget.expenditure[index].amount} onChange={this.handleChange}/>
													&euro; <Translate id="per month"/>
												</td>
												<td>
													<Input id={`calculator-expenditure-${index}-description`}
																		 label={<Translate id="Description"/>}
																		 value={this.state.budget.expenditure[index].description}
																		 onChange={this.handleChange}/>
												</td>
												<td>
													<button onClick={() => this.removeLine('expenditure', index)}>
														<i className="fa fa-minus"/>
													</button>
												</td>
											</tr>
										)
									})}
									<tr>
										<th colSpan={3} style={{textAlign: "center"}}>
											<Button onClick={this.addExpenditureRow} color="primary" variant="contained">
												<Translate id="New row"/>
											</Button>
										</th>
									</tr>
									<tr>
										<th colSpan={3} style={{textAlign: "center"}}>
											<h3>
												<Translate id="Result"/>
											</h3>
										</th>
									</tr>
									<tr>
										<th>
											<Translate id="Total Revenue"/>
										</th>
										<td>
											{this.formatNumber(this.totalIncome())}
											&euro; <Translate id="per month"/>
										</td>
									</tr>
									<tr>
										<th>
											<Translate id="Total Expenditure"/>
										</th>
										<td>
											{this.formatNumber(this.totalExpenditure())}
											&euro; <Translate id="per month"/>
										</td>
									</tr>
									<tr>
										<th>
											<Translate id="Monthly surplus"/>
										</th>
										<td>
											<b>
												{this.formatNumber(this.totalIncome() - this.totalExpenditure())}
												&euro; <Translate id="per month"/>
											</b>
										</td>
									</tr>
									</tbody>
								</table>

								<button onClick={this.saveBudget}>
									<Translate id="Save" />
								</button>
							</CardBody>
						</Card>
					</Row>
				</Container>
			</div>
		)
	}
}

export default withBootstrap(ShowBudgetPage)
