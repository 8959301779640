import React from "react";
import {Translate} from "react-localize-redux";
import {BootstrapContextProps} from "../Bootstrap/BootstrapContext";
import {firestoreDb} from "../Firebase";
import {UserJournal} from "../../Models";
import {withBootstrap} from "../Bootstrap/withBootstrap";
import firebase from "firebase";


interface LastJournalEntriesState {
	entries: firebase.firestore.QueryDocumentSnapshot[],
}

interface LastJournalEntriesProps {
	journalType: string,
}

class LastJournalEntries extends React.Component<BootstrapContextProps & LastJournalEntriesProps, LastJournalEntriesState> {
	state = {
		entries: [] as firebase.firestore.QueryDocumentSnapshot[],
	}

	componentDidMount(): void {
		this.loadJournal()
	}

	loadJournal(): void {
		const collectionPath = `users/${this.props.user?.uid}/journal`;
		console.log('Retrieving user journal', collectionPath)
		firestoreDb.collection(collectionPath)
			.where('journalType', '==', this.props.journalType)
			.orderBy('createdAt', 'desc')
			.limit(10)
			.onSnapshot((snapshot) => {
				this.setState({entries: snapshot.docs})
			})
	}

	render(): JSX.Element {
		return (
			<>
				<h3>
					<Translate id="Last entries"/>
				</h3>

				{this.state.entries.map((entry, idx) => {
					const userJournal = entry.data() as UserJournal

					return (
						<div key={idx}>
							{userJournal.title} - {userJournal.message}
						</div>
					)
				})}
			</>
		)
	}
}

export default withBootstrap(LastJournalEntries)
