/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// ##############################
// // // Chart variables
// #############################

interface AnyDictionary {
	[key: string]: any
}

// chartExample1 and chartExample2 options
export const chart_1_2_3_options = {
	maintainAspectRatio: false,
	legend: {
		display: false
	},
	tooltips: {
		backgroundColor: "#f5f5f5",
		titleFontColor: "#333",
		bodyFontColor: "#666",
		bodySpacing: 4,
		xPadding: 12,
		mode: "nearest",
		intersect: 0,
		position: "nearest"
	},
	responsive: true,
	scales: {
		yAxes: [
			{
				barPercentage: 1.6,
				gridLines: {
					drawBorder: false,
					color: "rgba(29,140,248,0.0)",
					zeroLineColor: "transparent"
				},
				ticks: {
					suggestedMin: 60,
					suggestedMax: 125,
					padding: 20,
					fontColor: "#9a9a9a"
				}
			}
		],
		xAxes: [
			{
				barPercentage: 1.6,
				gridLines: {
					drawBorder: false,
					color: "rgba(29,140,248,0.1)",
					zeroLineColor: "transparent"
				},
				ticks: {
					padding: 20,
					fontColor: "#9a9a9a"
				}
			}
		]
	}
};

// #########################################
// // // used inside src/views/Dashboard.js
// #########################################
export const chartExample1: AnyDictionary = {
	data1: (canvas: any) => {
		let ctx = canvas.getContext("2d");

		let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

		gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
		gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
		gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

		return {
			labels: [
				"JAN",
				"FEB",
				"MAR",
				"APR",
				"MAY",
				"JUN",
				"JUL",
				"AUG",
				"SEP",
				"OCT",
				"NOV",
				"DEC"
			],
			datasets: [
				{
					label: "My First dataset",
					fill: true,
					backgroundColor: gradientStroke,
					borderColor: "#1f8ef1",
					borderWidth: 2,
					borderDash: [],
					borderDashOffset: 0.0,
					pointBackgroundColor: "#1f8ef1",
					pointBorderColor: "rgba(255,255,255,0)",
					pointHoverBackgroundColor: "#1f8ef1",
					pointBorderWidth: 20,
					pointHoverRadius: 4,
					pointHoverBorderWidth: 15,
					pointRadius: 4,
					data: [100, 70, 90, 70, 85, 60, 75, 60, 90, 80, 110, 100]
				}
			]
		};
	},
	data2: (canvas: any) => {
		let ctx = canvas.getContext("2d");

		let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

		gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
		gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
		gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

		return {
			labels: [
				"JAN",
				"FEB",
				"MAR",
				"APR",
				"MAY",
				"JUN",
				"JUL",
				"AUG",
				"SEP",
				"OCT",
				"NOV",
				"DEC"
			],
			datasets: [
				{
					label: "My First dataset",
					fill: true,
					backgroundColor: gradientStroke,
					borderColor: "#1f8ef1",
					borderWidth: 2,
					borderDash: [],
					borderDashOffset: 0.0,
					pointBackgroundColor: "#1f8ef1",
					pointBorderColor: "rgba(255,255,255,0)",
					pointHoverBackgroundColor: "#1f8ef1",
					pointBorderWidth: 20,
					pointHoverRadius: 4,
					pointHoverBorderWidth: 15,
					pointRadius: 4,
					data: [80, 120, 105, 110, 95, 105, 90, 100, 80, 95, 70, 120]
				}
			]
		};
	},
	data3: (canvas: any) => {
		let ctx = canvas.getContext("2d");

		let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

		gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
		gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
		gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

		return {
			labels: [
				"JAN",
				"FEB",
				"MAR",
				"APR",
				"MAY",
				"JUN",
				"JUL",
				"AUG",
				"SEP",
				"OCT",
				"NOV",
				"DEC"
			],
			datasets: [
				{
					label: "My First dataset",
					fill: true,
					backgroundColor: gradientStroke,
					borderColor: "#1f8ef1",
					borderWidth: 2,
					borderDash: [],
					borderDashOffset: 0.0,
					pointBackgroundColor: "#1f8ef1",
					pointBorderColor: "rgba(255,255,255,0)",
					pointHoverBackgroundColor: "#1f8ef1",
					pointBorderWidth: 20,
					pointHoverRadius: 4,
					pointHoverBorderWidth: 15,
					pointRadius: 4,
					data: [60, 80, 65, 130, 80, 105, 90, 130, 70, 115, 60, 130]
				}
			]
		};
	},
	options: chart_1_2_3_options
};

// #########################################
// // // used inside src/views/Dashboard.js
// #########################################
export const chartExample2 = {
	data: (canvas: any) => {
		let ctx = canvas.getContext("2d");

		let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

		gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
		gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
		gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

		return {
			labels: ["JUL", "AUG", "SEP", "OCT", "NOV", "DEC"],
			datasets: [
				{
					label: "Data",
					fill: true,
					backgroundColor: gradientStroke,
					borderColor: "#1f8ef1",
					borderWidth: 2,
					borderDash: [],
					borderDashOffset: 0.0,
					pointBackgroundColor: "#1f8ef1",
					pointBorderColor: "rgba(255,255,255,0)",
					pointHoverBackgroundColor: "#1f8ef1",
					pointBorderWidth: 20,
					pointHoverRadius: 4,
					pointHoverBorderWidth: 15,
					pointRadius: 4,
					data: [80, 100, 70, 80, 120, 80]
				}
			]
		};
	},
	options: chart_1_2_3_options
};

// #########################################
// // // used inside src/views/Dashboard.js
// #########################################
export const chartExample3 = {
	data: (canvas: any) => {
		let ctx = canvas.getContext("2d");

		let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

		gradientStroke.addColorStop(1, "rgba(72,72,176,0.1)");
		gradientStroke.addColorStop(0.4, "rgba(72,72,176,0.0)");
		gradientStroke.addColorStop(0, "rgba(119,52,169,0)"); //purple colors

		return {
			labels: ["USA", "GER", "AUS", "UK", "RO", "BR"],
			datasets: [
				{
					label: "Countries",
					fill: true,
					backgroundColor: gradientStroke,
					hoverBackgroundColor: gradientStroke,
					borderColor: "#d048b6",
					borderWidth: 2,
					borderDash: [],
					borderDashOffset: 0.0,
					data: [53, 20, 10, 80, 100, 45]
				}
			]
		};
	},
	options: {
		maintainAspectRatio: false,
		legend: {
			display: false
		},
		tooltips: {
			backgroundColor: "#f5f5f5",
			titleFontColor: "#333",
			bodyFontColor: "#666",
			bodySpacing: 4,
			xPadding: 12,
			mode: "nearest",
			intersect: 0,
			position: "nearest"
		},
		responsive: true,
		scales: {
			yAxes: [
				{
					gridLines: {
						drawBorder: false,
						color: "rgba(225,78,202,0.1)",
						zeroLineColor: "transparent"
					},
					ticks: {
						suggestedMin: 60,
						suggestedMax: 120,
						padding: 20,
						fontColor: "#9e9e9e"
					}
				}
			],
			xAxes: [
				{
					gridLines: {
						drawBorder: false,
						color: "rgba(225,78,202,0.1)",
						zeroLineColor: "transparent"
					},
					ticks: {
						padding: 20,
						fontColor: "#9e9e9e"
					}
				}
			]
		}
	}
};

// #########################################
// // // used inside src/views/Dashboard.js
// #########################################
export const chartExample4 = {
	data: (canvas: any) => {
		let ctx = canvas.getContext("2d");

		let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

		gradientStroke.addColorStop(1, "rgba(66,134,121,0.15)");
		gradientStroke.addColorStop(0.4, "rgba(66,134,121,0.0)"); //green colors
		gradientStroke.addColorStop(0, "rgba(66,134,121,0)"); //green colors

		return {
			labels: ["JUL", "AUG", "SEP", "OCT", "NOV"],
			datasets: [
				{
					label: "My First dataset",
					fill: true,
					backgroundColor: gradientStroke,
					borderColor: "#00d6b4",
					borderWidth: 2,
					borderDash: [],
					borderDashOffset: 0.0,
					pointBackgroundColor: "#00d6b4",
					pointBorderColor: "rgba(255,255,255,0)",
					pointHoverBackgroundColor: "#00d6b4",
					pointBorderWidth: 20,
					pointHoverRadius: 4,
					pointHoverBorderWidth: 15,
					pointRadius: 4,
					data: [90, 27, 60, 12, 80]
				}
			]
		};
	},
	options: {
		maintainAspectRatio: false,
		legend: {
			display: false
		},
		tooltips: {
			backgroundColor: "#f5f5f5",
			titleFontColor: "#333",
			bodyFontColor: "#666",
			bodySpacing: 4,
			xPadding: 12,
			mode: "nearest",
			intersect: 0,
			position: "nearest"
		},
		responsive: true,
		scales: {
			yAxes: [
				{
					barPercentage: 1.6,
					gridLines: {
						drawBorder: false,
						color: "rgba(29,140,248,0.0)",
						zeroLineColor: "transparent"
					},
					ticks: {
						suggestedMin: 50,
						suggestedMax: 125,
						padding: 20,
						fontColor: "#9e9e9e"
					}
				}
			],
			xAxes: [
				{
					barPercentage: 1.6,
					gridLines: {
						drawBorder: false,
						color: "rgba(0,242,195,0.1)",
						zeroLineColor: "transparent"
					},
					ticks: {
						padding: 20,
						fontColor: "#9e9e9e"
					}
				}
			]
		}
	}
};

// #########################################
// // // used inside src/views/Charts.js
// #########################################
export const chartExample5 = {
	data: (canvas: any) => {
		let ctx = canvas.getContext("2d");
		var gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

		gradientStroke.addColorStop(1, "rgba(72,72,176,0.4)");
		gradientStroke.addColorStop(0.8, "rgba(72,72,176,0.2)");
		gradientStroke.addColorStop(0, "rgba(119,52,169,0)"); //purple colors
		return {
			labels: ["JUL", "AUG", "SEP", "OCT", "NOV", "DEC"],
			datasets: [
				{
					label: "Data",
					fill: true,
					backgroundColor: gradientStroke,
					borderColor: "#ba54f5",
					borderWidth: 2,
					borderDash: [],
					borderDashOffset: 0.0,
					pointBackgroundColor: "#be55ed",
					pointBorderColor: "rgba(255,255,255,0)",
					pointHoverBackgroundColor: "#be55ed",
					//pointHoverBorderColor:'rgba(35,46,55,1)',
					pointBorderWidth: 20,
					pointHoverRadius: 4,
					pointHoverBorderWidth: 15,
					pointRadius: 4,
					data: [80, 100, 70, 80, 120, 80]
				}
			]
		};
	},
	options: {
		maintainAspectRatio: false,
		legend: {
			display: false
		},
		tooltips: {
			backgroundColor: "#f5f5f5",
			titleFontColor: "#333",
			bodyFontColor: "#666",
			bodySpacing: 4,
			xPadding: 12,
			mode: "nearest",
			intersect: 0,
			position: "nearest"
		},
		responsive: true,
		scales: {
			yAxes: [
				{
					barPercentage: 1.6,
					gridLines: {
						drawBorder: false,
						color: "rgba(186,84,245,0.1)",
						zeroLineColor: "transparent"
					},
					ticks: {
						suggestedMin: 60,
						suggestedMax: 125,
						padding: 20,
						fontColor: "#9e9e9e"
					}
				}
			],
			xAxes: [
				{
					barPercentage: 1.6,
					gridLines: {
						drawBorder: false,
						color: "rgba(186,84,245,0.1)",
						zeroLineColor: "transparent"
					},
					ticks: {
						padding: 20,
						fontColor: "#9e9e9e"
					}
				}
			]
		}
	}
};
// #########################################
// // // used inside src/views/Charts.js
// #########################################
export const chartExample6 = {
	data: (canvas: any) => {
		let ctx = canvas.getContext("2d");
		var gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

		gradientStroke.addColorStop(1, "rgba(0,135,191,0.2)");
		gradientStroke.addColorStop(0.8, "rgba(0,135,191,0.1)");
		gradientStroke.addColorStop(0, "rgba(0,84,119,0)"); //blue colors
		return {
			labels: ["JUL", "AUG", "SEP", "OCT", "NOV", "DEC"],
			datasets: [
				{
					label: "Data",
					fill: true,
					backgroundColor: gradientStroke,
					borderColor: "#2380f7",
					borderWidth: 2,
					borderDash: [],
					borderDashOffset: 0.0,
					pointBackgroundColor: "#2380f7",
					pointBorderColor: "rgba(255,255,255,0)",
					pointHoverBackgroundColor: "#2380f7",
					pointBorderWidth: 20,
					pointHoverRadius: 4,
					pointHoverBorderWidth: 15,
					pointRadius: 4,
					data: [80, 100, 70, 80, 120, 80]
				}
			]
		};
	},
	options: {
		maintainAspectRatio: false,
		legend: {
			display: false
		},
		tooltips: {
			backgroundColor: "#f5f5f5",
			titleFontColor: "#333",
			bodyFontColor: "#666",
			bodySpacing: 4,
			xPadding: 12,
			mode: "nearest",
			intersect: 0,
			position: "nearest"
		},
		responsive: true,
		scales: {
			yAxes: [
				{
					barPercentage: 1.6,
					gridLines: {
						drawBorder: false,
						color: "rgba(29,140,248,0.0)",
						zeroLineColor: "transparent"
					},
					ticks: {
						suggestedMin: 60,
						suggestedMax: 125,
						padding: 20,
						fontColor: "#9e9e9e"
					}
				}
			],
			xAxes: [
				{
					barPercentage: 1.6,
					gridLines: {
						drawBorder: false,
						color: "rgba(29,140,248,0.1)",
						zeroLineColor: "transparent"
					},
					ticks: {
						padding: 20,
						fontColor: "#9e9e9e"
					}
				}
			]
		}
	}
};
// #########################################
// // // used inside src/views/Charts.js
// #########################################
export const chartExample7 = {
	data: (canvas: any) => {
		let ctx = canvas.getContext("2d");
		var gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

		gradientStroke.addColorStop(1, "rgba(253,93,147,0.8)");
		gradientStroke.addColorStop(0, "rgba(253,93,147,0)"); //blue colors
		return {
			labels: ["JUL", "AUG", "SEP", "OCT", "NOV", "DEC"],
			datasets: [
				{
					label: "Data",
					fill: true,
					backgroundColor: gradientStroke,
					hoverBackgroundColor: gradientStroke,
					borderColor: "#ff5991",
					borderWidth: 2,
					borderDash: [],
					borderDashOffset: 0.0,
					data: [80, 100, 70, 80, 120, 80]
				}
			]
		};
	},
	options: {
		maintainAspectRatio: false,
		legend: {
			display: false
		},
		tooltips: {
			backgroundColor: "#f5f5f5",
			titleFontColor: "#333",
			bodyFontColor: "#666",
			bodySpacing: 4,
			xPadding: 12,
			mode: "nearest",
			intersect: 0,
			position: "nearest"
		},
		responsive: true,
		scales: {
			yAxes: [
				{
					gridLines: {
						drawBorder: false,
						color: "rgba(253,93,147,0.1)",
						zeroLineColor: "transparent"
					},
					ticks: {
						suggestedMin: 60,
						suggestedMax: 125,
						padding: 20,
						fontColor: "#9e9e9e"
					}
				}
			],
			xAxes: [
				{
					gridLines: {
						drawBorder: false,
						color: "rgba(253,93,147,0.1)",
						zeroLineColor: "transparent"
					},
					ticks: {
						padding: 20,
						fontColor: "#9e9e9e"
					}
				}
			]
		}
	}
};
// #########################################
// // // used inside src/views/Charts.js
// #########################################
export const chartExample8 = {
	data: {
		labels: ["JUL", "AUG", "SEP", "OCT", "NOV", "DEC"],
		datasets: [
			{
				label: "Data",
				fill: true,
				backgroundColor: "#ff8a76",
				hoverBackgroundColor: " #ff8a76",
				borderColor: "#ff8a76",
				borderWidth: 2,
				borderDash: [],
				borderDashOffset: 0.0,
				data: [80, 100, 70, 80, 120, 80, 130]
			},
			{
				label: "Data",
				fill: true,
				backgroundColor: "#2782f0",
				hoverBackgroundColor: " #2782f0",
				borderColor: "#2782f0",
				borderWidth: 2,
				borderDash: [],
				borderDashOffset: 0.0,
				data: [60, 110, 90, 70, 90, 100]
			}
		]
	},
	options: {
		maintainAspectRatio: false,
		legend: {
			display: false
		},
		tooltips: {
			backgroundColor: "#f5f5f5",
			titleFontColor: "#333",
			bodyFontColor: "#666",
			bodySpacing: 4,
			xPadding: 12,
			mode: "nearest",
			intersect: 0,
			position: "nearest"
		},
		responsive: true,
		scales: {
			yAxes: [
				{
					gridLines: {
						drawBorder: false,
						color: "rgba(29,140,248,0.1)",
						zeroLineColor: "transparent"
					},
					ticks: {
						suggestedMin: 60,
						suggestedMax: 120,
						padding: 20,
						fontColor: "#9e9e9e"
					}
				}
			],
			xAxes: [
				{
					gridLines: {
						drawBorder: false,
						color: "rgba(29,140,248,0.1)",
						zeroLineColor: "transparent"
					},
					ticks: {
						padding: 20,
						fontColor: "#9e9e9e"
					}
				}
			]
		}
	}
};
// #########################################
// // // used inside src/views/Charts.js
// #########################################
export const chartExample9 = {
	data: {
		labels: [1, 2],
		datasets: [
			{
				label: "Emails",
				pointRadius: 0,
				pointHoverRadius: 0,
				backgroundColor: ["#00c09d", "#e2e2e2"],
				borderWidth: 0,
				data: [60, 40]
			}
		]
	},
	options: {
		cutoutPercentage: 70,
		legend: {
			display: false
		},

		tooltips: {
			backgroundColor: "#f5f5f5",
			titleFontColor: "#333",
			bodyFontColor: "#666",
			bodySpacing: 4,
			xPadding: 12,
			mode: "nearest",
			intersect: 0,
			position: "nearest"
		},

		scales: {
			yAxes: [
				{
					display: 0,
					ticks: {
						display: false
					},
					gridLines: {
						drawBorder: false,
						zeroLineColor: "transparent",
						color: "rgba(255,255,255,0.05)"
					}
				}
			],

			xAxes: [
				{
					display: 0,
					barPercentage: 1.6,
					gridLines: {
						drawBorder: false,
						color: "rgba(255,255,255,0.1)",
						zeroLineColor: "transparent"
					},
					ticks: {
						display: false
					}
				}
			]
		}
	}
};
// #########################################
// // // used inside src/views/Charts.js
// #########################################
export const chartExample10 = {
	data: {
		labels: [1, 2, 3],
		datasets: [
			{
				label: "Emails",
				pointRadius: 0,
				pointHoverRadius: 0,
				backgroundColor: ["#ff8779", "#2a84e9", "#e2e2e2"],
				borderWidth: 0,
				data: [60, 40, 20]
			}
		]
	},
	options: {
		cutoutPercentage: 70,
		legend: {
			display: false
		},
		tooltips: {
			backgroundColor: "#f5f5f5",
			titleFontColor: "#333",
			bodyFontColor: "#666",
			bodySpacing: 4,
			xPadding: 12,
			mode: "nearest",
			intersect: 0,
			position: "nearest"
		},
		scales: {
			yAxes: [
				{
					display: 0,
					ticks: {
						display: false
					},
					gridLines: {
						drawBorder: false,
						zeroLineColor: "transparent",
						color: "rgba(255,255,255,0.05)"
					}
				}
			],
			xAxes: [
				{
					display: 0,
					barPercentage: 1.6,
					gridLines: {
						drawBorder: false,
						color: "rgba(255,255,255,0.1)",
						zeroLineColor: "transparent"
					},
					ticks: {
						display: false
					}
				}
			]
		}
	}
};
